import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import noImage from './images/noImage.png';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BrowserNotSupportedIcon from '@mui/icons-material/BrowserNotSupported';
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";

import DropDown from './components/DropDown';
import KImage from './components/KImage';
import Config from './components/ClientConfig';
import Client from './components/Client';
import BackOfficeConfig from './Config';

import swal from 'sweetalert';

/* const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
})); */


class SubcategoryRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      edit: false,
      row: {},
      category: "0",
      somethingHasChanged: false,
      nameValid: false,
      descriptionValid: false,
    };

    
    this.handleEditClick = this.handleEditClick.bind(this);
    this.getImage = this.getImage.bind(this);
    this.getImage_off = this.getImage_off.bind(this);    
    this.changeValue = this.changeValue.bind(this);
    this.pushData = this.pushData.bind(this);
  }

  handleEditClick ( ) {
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
  }
  getImage ( newImage ) {
    if ( newImage ) {
      if ( this.state.row ) {
        let nRow = this.state.row;
        nRow.image = newImage;
        this.setState ( { row: nRow }, this.setState ( { somethingHasChanged: true } ) );
      }
    }
  }

  getImage_off ( newImage ) {
    if ( newImage ) {
      if ( this.state.row ) {
        let nRow = this.state.row;
        nRow.image_off = newImage;
        this.setState ( { row: nRow }, this.setState ( { somethingHasChanged: true } ) );
      }
    }
  }

  componentDidMount() {
    let row = {};
    if ( this.props.row ) {
      row = this.props.row;
      this.setState ( { row: row } );
      if ( row.name.length > 0 ) this.setState ( {nameValid: true } );
      if ( row.description.length > 0 ) this.setState ( {descriptionValid: true } );
    }
    if ( this.props.category ) this.setState ( { category: this.props.category } );
    if ( this.props.edit ) this.setState ( { edit: true } );
  }

  componentDidUpdate ( prevProps ) {
    if ( this.props != prevProps ) {
      if ( this.props.row ) {
        let row = this.props.row;
        this.setState ( { row: row } );
        if ( row.name.length > 0 ) this.setState ( {nameValid: true } );
        if ( row.description.length > 0 ) this.setState ( {descriptionValid: true } );
      }
      if ( this.props.category ) this.setState ( { category: this.props.category } );
      if ( this.props.edit ) this.setState ( { edit: true } );
    }
  }

  changeValue ( e ) {
    let id = null;
    if ( e.target.id !== null ) id = e.target.id;
    let value = null;
    if  ( e.target.value !== null ) value = e.target.value;
    //if ( id !== null && value !== null && value !== undefined ) {
    if ( id ) {
      let row = {};
      if ( this.state.row ) row = this.state.row;
      switch ( id ) {
        case "name":
          if ( value.length > 0 ) {
            row.name = value;
            this.setState ( { nameValid: true });
          } else {
            row.name = "";
            this.setState ( { nameValid: false });
          }
          this.setState ( { somethingHasChanged: true } );
          break;
        case "description":
          if ( value.length > 0 ) {
            row.description = value;
            this.setState ( { descriptionValid: true });
          } else {
            row.description = "";
            this.setState ( { descriptionValid: false });
          }
          this.setState ( { somethingHasChanged: true } );
          break;
        case "isForSale":            
            row.isForSale = !row.isForSale;
            this.setState ( { somethingHasChanged: true } );
          break;
      }
      this.setState ( { row: row });
    }
  }

  pushData ( e ) {
    if ( this.state.somethingHasChanged && this.state.nameValid && this.state.descriptionValid ) {
      let qData = {
        category: this.state.category,
        data: this.state.row
      };
      let rdata = this.state.client.pushData ( Config.API_URL_PRODUCT_SUBCATEGORY_ADD, qData );
      if ( rdata ) {
        if ( rdata.status == "OK" ) {
          let uSubCategory = this.state.row;
          uSubCategory.iref = rdata.iref;
          this.setState ( { row: uSubCategory } );
          //this.props.subCategoryAdded ( );
          this.handleEditClick ( );
          swal ( "Success", "Successfully added/modified sub category ! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            //this.props.personUpdated ( );
            this.setState ( {edit: false} );
            this.setState ( { somethingHasChanged: false } );
            this.props.subCategoryAdded ( );
            //this.setState ( { addSubCategory: false } );
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Error", "Add/Update sub category failed !\n\n" + rdata.message, "error" );
        }
      }
    } else {
      swal ( "Error", "Add/Update sub category failed ! Some fields are not validated !", "error" );
    }
  }

  render() {
    let key = "";
    let iref = "";
    let name = "";
    let img = noImage;
    let img_off = noImage;
    let description = "";
    let isForSale = false;

    if ( this.state.row ) {
      let row = this.state.row;
      if ( row.sckey ) key = row.sckey;
      if ( row.iref ) iref = row.iref;
      if ( row.name ) name = row.name;
      if ( row.image ) img = row.image;
      if ( row.image_off ) img_off = row.image_off;
      if ( row.description ) description = row.description;
      if ( row.isForSale ) isForSale = row.isForSale;
    }

    let kImage = {
      data: img,
      height: 128,
      width: 128
    };
    let imageName = name + "_on";

    let kImage_off = {
      data: img_off,
      height: 128,
      width: 128
    };
    let imageName_off = name + "_off";

    let showSave = false;
    if ( this.state.edit === true && this.state.somethingHasChanged === true && this.state.nameValid === true && this.state.descriptionValid === true )
      showSave = true;

    return (
      <React.Fragment>
        <TableRow key={key}>
        <TableCell component="th" scope="row">
          {iref}
        </TableCell>
          <TableCell component="th" scope="row">
          {this.state.edit ?
            <TextField
              id="name"
              label="Name"
              variant="outlined"
              value={name || ''}
              onChange={this.changeValue}
              onFocus={event => { event.target.select(); }}
              inputProps={{maxLength:"100"}}
              error={this.state.nameValid === false}
              required={true}
              sx={{minWidth: "100px"}}
            />
            : name
          }
          </TableCell>
          <TableCell align="left">
          {this.state.edit ?
            <TextField
              id="description"
              label="Description"
              variant="outlined"
              value={description || ''}
              onChange={this.changeValue}
              onFocus={event => { event.target.select(); }}
              inputProps={{maxLength:"255"}}
              error={this.state.descriptionValid === false}
              required={true}
              sx={{minWidth: "100px"}}
            />
            : description
          }
          </TableCell>
          <TableCell align="left">
            {this.state.edit ?
              <Checkbox id="isForSale" color="success" checked={isForSale} onChange={this.changeValue}/>
              :
              <Checkbox  color="success" checked={isForSale} disabled/>
            }
          </TableCell>
          <TableCell>
            <KImage
              edit = {this.state.edit}
              image = {kImage}
              imageText = {imageName}
              saveBack = {this.getImage}
           />
          </TableCell>
          <TableCell align="left">
            <KImage
              edit = {this.state.edit}
              image = {kImage_off}
              imageText = {imageName_off}
              saveBack = {this.getImage_off}
           />
          </TableCell>
          
          <TableCell align="right">
            <IconButton aria-label="settings" onClick={this.handleEditClick}>
                <EditIcon
                  sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}                  
               />
            </IconButton>
            {showSave ?
              <IconButton aria-label="settings" onClick={this.pushData}>
                <SaveIcon
                  sx={{ color: "#4287f5" }}                
                />
              </IconButton>
              : ""}
          </TableCell>
        </TableRow>
      </React.Fragment>
      
    );

  }
}

  class Row extends React.Component {

    constructor(props) {
      super(props);
  
      this.state = {
        client: new Client ( "none" ),  
        categoriesWithSubCategories: null, 
        edit: false,   
        opened: false,
        row: {},
        category: "0",
        nameValid: false,
        descriptionValid: false,
        image_off: noImage,
        somethingHasChanged: false,
        addSubCategory: false,
      };
  
      this.toggleAdd = this.toggleAdd.bind(this);
      this.handleEditClick = this.handleEditClick.bind(this);
      this.handleOpen = this.handleOpen.bind(this);
      this.getImage = this.getImage.bind(this);
      this.getImage_off = this.getImage_off.bind(this);
      this.changeValue = this.changeValue.bind(this);
      this.pushData = this.pushData.bind(this);
      this.changeOperation = this.changeOperation.bind(this);
      this.subCategoryAdded = this.subCategoryAdded.bind(this);
    }

    handleOpen ( ) {
      let opened = this.state.opened;
      this.setState ( { opened: !opened } );
    }

    handleEditClick ( ) {
      let cEdit = !this.state.edit;
      this.setState({ edit: !this.state.edit });
    }
    
    getImage ( newImage ) {
      if ( newImage ) {
        if ( this.state.row ) {
          let nRow = this.state.row;
          nRow.image = newImage;
          this.setState ( { row: nRow }, this.setState ( { somethingHasChanged: true } ) );
        }
      }
    }
  
    getImage_off ( newImage ) {
      if ( newImage ) {
        if ( this.state.row ) {
          let nRow = this.state.row;
          nRow.image_off = newImage;
          this.setState ( { row: nRow }, this.setState ( { somethingHasChanged: true } ) );
        }
      }
    }

    changeValue ( e ) {
      let id = null;
      if ( e.target.id !== null ) id = e.target.id;
      let value = null;
      if  ( e.target.value !== null ) value = e.target.value;
      //if ( id !== null && value !== null && value !== undefined ) {
      if ( id ) {
        let row = {};
        if ( this.state.row ) row = this.state.row;
        switch ( id ) {
          case "name":
            if ( value.length > 0 ) {
              row.name = value;
              this.setState ( { nameValid: true });
            } else {
              row.name = "";
              this.setState ( { nameValid: false });
            }
            this.setState ( { somethingHasChanged: true } );
            break;
          case "description":
            if ( value.length > 0 ) {
              row.description = value;
              this.setState ( { descriptionValid: true });
            } else {
              row.description = "";
              this.setState ( { descriptionValid: false });
            }
            this.setState ( { somethingHasChanged: true } );
            break;
          case "isForSale":            
              row.isForSale = !row.isForSale;
              this.setState ( { somethingHasChanged: true } );
            break;
        }
        this.setState ( { row: row });
      }
    }

    componentDidMount() {
      let row = {};
      if ( this.props.row ) {
        row = this.props.row;
        this.setState ( { row: row } );
        if ( row.name.length > 0 ) this.setState ( {nameValid: true } );
        if ( row.description.length > 0 ) this.setState ( {descriptionValid: true } );
      }
      if ( this.props.category ) this.setState ( { category: this.props.category } );
      if ( this.props.edit ) this.setState ( { edit: true } );
    }

    componentDidUpdate ( prevProps ) {
      if ( this.props != prevProps ) {
        if ( this.props.row ) {
          let row = this.props.row;
          this.setState ( { row: row } );
          if ( row.name.length > 0 ) this.setState ( {nameValid: true } );
          if ( row.description.length > 0 ) this.setState ( {descriptionValid: true } );
        }
        if ( this.props.category ) this.setState ( { category: this.props.category } );
        if ( this.props.edit ) this.setState ( { edit: true } );
      }
    }

    toggleAdd ( ) {
      let addButton = this.state.addSubCategory;
      this.setState ( {addSubCategory: !addButton} );
      //this.props.toggleAdd ( !addButton );
    }

    pushData ( e ) {
      if ( this.state.somethingHasChanged && this.state.nameValid && this.state.descriptionValid ) {
        let rdata = this.state.client.pushData ( Config.API_URL_PRODUCT_CATEGORY_ADD, this.state.row );
        if ( rdata ) {
          if ( rdata.status == "OK" ) {
            let uCategory = this.state.row;
            uCategory.iref = rdata.iref;
            this.setState ( { row: uCategory } );
            this.handleEditClick ( );
            swal ( "Success", "Successfully added/modified category ! (" + rdata.iref + ")", "success", {
              buttons: false,
              timer: 2000,
            })
            .then((value) => {
              //this.props.personUpdated ( );
              this.setState ( {edit: false} );
              this.setState ( { somethingHasChanged: false } );
              this.props.categoryAdded ();
            });        
          } else {
            if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
            swal ( "Error", "Add/Update category failed !\n\n" + rdata.message, "error" );
          }
        }
      } else {
        swal ( "Error", "Add/Update category failed ! Some fields are not validated !", "error" );
      }
    }

    changeOperation ( value ) {
      if ( value !== undefined ) {
        let uRow = this.state.row;
        uRow.operation = value;
        this.setState ( { row: uRow } );
        this.setState ( { somethingHasChanged: true } );
      }
    }

    subCategoryAdded ( ) {
      //this.props.getData ( );
      let queryData = {};
      queryData [ "orderBy_Name" ] = true;
      let data = this.state.client.getData ( Config.API_URL_PRODUCT_CATEGORY + this.state.row.iref, queryData );
      console.log ( data );
      this.setState ( { row: data });
      this.setState ( {addSubCategory: false } );
    }

    render() {
      let row = {};
      let subCategory = [];
      let catIref = "0";
      let image = noImage;
      let image_off = noImage;
      let isForSale = false;
      let operationType = 9;
      let operationType_text = "Undefined";
      let name = "";
      let description = "";

      if ( this.state.row ) {
        row = this.state.row;
        if ( row.iref ) catIref = row.iref;
        if ( row.name ) name = row.name;
        if ( row.description ) description = row.description;
        if ( row.image ) image = row.image;
        if ( row.image_off ) image_off = row.image_off;
        if ( row.isForSale ) isForSale = row.isForSale;
        if ( row.subCategory ) subCategory = row.subCategory;
        if ( row.operation !== undefined ) {
          operationType = parseInt(row.operation);
          operationType_text = BackOfficeConfig.CATEGORY_OPERATION_TYPES [ parseInt(operationType) ];
        }
      }
      let kImage = {
        data: image,
        height: 128,
        width: 128
      };
      let imageName = name + "_on";
  
      let kImage_off = {
        data: image_off,
        height: 128,
        width: 128
      };
      let imageName_off = name + "_off";
      let showSave = false;
      if ( this.state.edit === true && this.state.somethingHasChanged === true && this.state.nameValid === true && this.state.descriptionValid === true )
        showSave = true;

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>

          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => this.handleOpen()}
            >
              {this.state.opened ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            </IconButton>
            {catIref}
          </TableCell>

          <TableCell component="th" scope="row">
            {this.state.edit ?
              <TextField
                id="name"
                label="Name"
                variant="outlined"
                value={name || ''}
                onChange={this.changeValue}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"100"}}
                error={this.state.nameValid === false}
                required={true}
                sx={{minWidth: "100px"}}
             />
              : name
            }
          </TableCell>
          <TableCell align="left">
            {this.state.edit ?
              <TextField
                id="description"
                label="Description"
                variant="outlined"
                value={description || ''}
                onChange={this.changeValue}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"255"}}
                error={this.state.descriptionValid === false}
                required={true}
             />
              : description
            }
          </TableCell>

          <TableCell align="left">
            {this.state.edit ?
              <Checkbox id="isForSale" color="success" checked={isForSale} onChange={this.changeValue}/>
              :
              <Checkbox  color="success" checked={isForSale} disabled/>
            }

          </TableCell>

          <TableCell>
            {this.state.edit ?           
            <DropDown
              name="Operation"
              value={operationType}
              doptions={BackOfficeConfig.CATEGORY_OPERATION_TYPES}
              hasIcons={false} 
              minWidth={100}
              onChangeFunction={this.changeOperation}
            />
            : operationType_text }
          </TableCell>


          <TableCell align="left">
            {/* <Box
              component="img"
              sx={{
                height: 48,
                width: 48,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
                border: '1px dashed grey'
              }}
              alt="No Image"
              src={img}
           /> */}
            <KImage
              edit = {this.state.edit}
              image = {kImage}
              imageText = {imageName}
              saveBack = {this.getImage}
           />
          </TableCell>
          <TableCell align="left">
            {/* <Box
              component="img"
              sx={{
                height: 48,
                width: 48,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
                border: '1px dashed grey'
              }}
              alt="No Image"
              src={img_off}
           /> */}
            <KImage
              edit = {this.state.edit}
              image = {kImage_off}
              imageText = {imageName_off}
              saveBack = {this.getImage_off}
           />
          </TableCell>
          

          <TableCell align="right">
            <IconButton aria-label="settings" onClick={this.handleEditClick}>
                <EditIcon
                  sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}                  
               />
            </IconButton>
            {showSave ?
              <IconButton aria-label="settings" onClick={this.pushData}>
                <SaveIcon
                  sx={{ color: "#4287f5" }}                
                />
            </IconButton>
            : ""}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={this.state.opened} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="body1" gutterBottom component="div">
                  Subcategories
                </Typography>
                <Table size="small" aria-label="purchases" sx={{backgroundColor: "#eeeee4"}}>
                  <TableHead sx={{backgroundColor: "#ddddd4"}}>
                    <TableRow>
                      <TableCell align="left">
                        <IconButton aria-label="Add Location" title="Add Location" onClick={() => this.toggleAdd()}>       
                          <Avatar alt="Add Location" sx={{ width: 24, height: 24, color: "#273442" }}>
                            {this.state.addSubCategory ? <FaMinusCircle/> : <FaPlusCircle/>}
                          </Avatar>
                        </IconButton>
                      </TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Description</TableCell>
                      <TableCell align="left">Is For Sale</TableCell>
                      <TableCell align="left">Image (On)</TableCell>
                      <TableCell align="left">Image (Off)</TableCell>                      
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.addSubCategory ?
                      <SubcategoryRow key={0} category={catIref} edit={true} subCategoryAdded={this.subCategoryAdded}/>
                      : ""
                    }
                    {subCategory.map((scData) => (
                      <SubcategoryRow
                        key = {scData.iref}
                        row = {scData}
                        category = {catIref}
                        subCategoryAdded={this.subCategoryAdded}
                      >
                      </SubcategoryRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}

class Categories extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),  
      categoriesWithSubCategories: null,
      addCategory: false
    };

    this.getData = this.getData.bind(this);
    this.toggleAdd = this.toggleAdd.bind(this);
    this.categoryAdded = this.categoryAdded.bind(this);
  }

  componentDidMount() {
    this.getData ( );
  }

  toggleAdd ( ) {
    let addButton = this.state.addCategory;
    this.setState ( {addCategory: !addButton} );
    //this.props.toggleAdd ( !addButton );
  }

  getData ( ) {
    let queryData = {};
    queryData [ "orderBy_Name" ] = true;
    let categoriesWithSubCategories = this.state.client.getData ( Config.API_URL_PRODUCT_CATEGORY_WITH_SUBCATEGORIES, queryData );
    this.setState ( { categoriesWithSubCategories: categoriesWithSubCategories.productCategories_withSubCategories });
  }

  categoryAdded ( ) {
    this.getData ( );
    this.setState ( { addCategory: false } );
  }

  render() {

    let rows = null;
    if (this.state.categoriesWithSubCategories) {
      rows = this.state.categoriesWithSubCategories;
    }

    return (
      <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead sx={{backgroundColor: "#273442", color: "#FFFFFF !important" }}>
          <TableRow>
            <TableCell>
              <IconButton aria-label="Add Category" title="Add Category" onClick={() => this.toggleAdd()}>       
                <Avatar alt="Add Category" sx={{ width: 24, height: 24, color: "#273442" }}>
                  {this.state.addCategory ? <FaMinusCircle/> : <FaPlusCircle/>}
                </Avatar>
              </IconButton>
            </TableCell>
            <TableCell sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Name</TableCell>
            <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Description</TableCell>
            <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Is For Sale</TableCell>
            <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Operation</TableCell>
            <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Image (On)</TableCell>
            <TableCell align="left" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Image (Off)</TableCell>            
            <TableCell align="right" sx={{backgroundColor: "#273442", color: "#FFFFFF !important"}}>Action</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {this.state.addCategory ?
          <Row key={0} edit={true} categoryAdded={this.categoryAdded}/>
          : ""
          }
          {rows ? 
            rows.map((row) => (
            <Row key={row.iref} row={row} categoryAdded={this.categoryAdded}/>
            ))
            :
            ""
          }
        </TableBody>
      </Table>
    </TableContainer>
    );
  }
}

export default Categories;
