import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser, FaMicrophoneAlt } from "react-icons/fa";
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Autocomplete from '@mui/material/Autocomplete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isDecimal from 'validator/lib/isDecimal';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import swal from 'sweetalert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';

import noImage from './../images/noImage.png';
import idle from './../images/icons/idle_on.png';
import registered from './../images/icons/registered_on.png';
import active from './../images/icons/active_on.png';
import discontinued from './../images/icons/discontinued_on.png';

import DropDown from './DropDown';

import Client from './Client';
import Config from './ClientConfig';
import BackOfficeConfig from '../Config';

let theme = createTheme();

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#273442",
    color: theme.palette.common.white,
    paddingLeft: "4px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingLeft: "4px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function thousandsSeparator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class ClientAccount extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      expanded: false,
      edit: false,
      saveButtonVisible: false,
      //combo_mainImage: null,
      row: {
        user: { }
      },      
      somethingHasChanged: false,
      newUser: false,
      languages: [],
      saving: false,
      usersSearch: [],
      openClean: false
    };

    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.detachPerson = this.detachPerson.bind(this); 
    this.pushData = this.pushData.bind(this); 
    this.userChange = this.userChange.bind(this);
    this.userSearch = this.userSearch.bind(this);
    this.cleanClient = this.cleanClient.bind(this);
    this.openClean = this.openClean.bind(this);
    this.closeClean = this.closeClean.bind(this);
       
  }

  componentDidMount() {
    if ( Object.entries(this.props.row).length === 0 ) {
      if ( this.props.newUser ) {
        this.setState ( { newUser: true } );
      }
      this.handleEditClick ( );
    } else {
      if ( this.props.row !== null ) {
      }      
    }

    let uRow = this.props.row;
    this.setState ( { row: uRow } ); 
  }

  handleExpandClick ( ) {
    this.setState({ expanded: !this.state.expanded });
  }

  handleEditClick ( ) {
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
    if ( this.state.expanded === false && cEdit === true )
      this.state.expanded = true;
    if ( this.state.expanded === true && cEdit === false )
      this.state.expanded = false;
    this.setState({ saveButtonVisible: cEdit });
  }

  detachPerson ( ) {
    let uRow = this.state.row;
    if ( uRow ) {
      if ( uRow.person ) {
        let prevPerson = uRow.person;
        uRow.person = {};
        uRow.prevPerson = prevPerson;
        this.setState ( { company: uRow } );
        this.setState ( { somethingHasChanged: true } );
        this.setState ( { personValid: false} );
      }
    }    
  }

  userSearch ( e ) {
    if ( e.target.value.length >= 3 ) {
      let stringLength = e.target.value.length;
      let limit = (stringLength - 2) * 20;
      let queryData = {
        "nameLike" : e.target.value,
        "notLinked_client": true,
        "user_type": 5,
        "limit": limit
      };
      //
      let data = this.state.client.filterUsers ( queryData );
      let newUsers = [];
      let users = [];
      
      //if ( data.usersProfiles.length > 0 ) {
        Object.keys(data.usersProfiles).forEach(key => {
          let fData = data.usersProfiles [ key ];
          let userType = BackOfficeConfig.USER_TYPES [ fData.user_type ];
          let lineText = fData.iref + " - " + userType + "[" + fData.username + "] - " + fData.first_name + " " + fData.last_name + " (" + fData.ruid + ", " + fData.email + ")";
          let newObj = { key: fData.iref, label: lineText };
          newUsers.push ( newObj );
          users.push ( fData );
          //counties [ fData.iref ] = fData.name;      
        });
      //}
      this.setState ( { usersSearch: newUsers });
    }
  }

  userChange ( e ) {
    let text = e.target.textContent;
    let repIref = text.substring ( 0, text.indexOf(" - ") );
    let queryData = { iref: repIref };
    //let data = this.state.client.getData ( Config.USER_API_URL + repIref, queryData );
    let data = this.state.client.getData ( Config.API_URL_USERS_PROFILES, queryData );
    let userWithPerson = {};
    if ( data.usersProfiles ) {
      if ( data.usersProfiles.length == 1 ) userWithPerson = data.usersProfiles [ 0 ];
    }
    let uRow = this.state.row;
    if ( userWithPerson ) {
      uRow.user = userWithPerson;
      this.setState ( { person: uRow } );    
      this.setState ( { somethingHasChanged: true } );
    }    
  }

  pushData ( e ) {

    if ( this.state.somethingHasChanged ) {

      this.setState ( { saving : true }, () => {        
        let rdata = this.state.client.pushData ( Config.API_URL_CLIENT_ADD, this.state.row );
        if ( rdata ) {
          if ( rdata.status == "OK" ) {
            /* let uRow = this.state.row;
            uRow.iref = rdata.iref;
            this.setState ( { row: uRow } ); */
            //this.setState ( { row: {} } );
            this.handleEditClick ( );            
            swal ( "Success", "Successfully added/modified client ! (" + rdata.iref + ")", "success", {
              buttons: false,
              timer: 2000,
            })
            .then((value) => {
              this.setState ( { saving : false } );
              this.props.clientUpdated ( );
              this.setState ( { somethingHasChanged: false } );
              this.setState ( { expanded: false } );
            });        
          } else {
            if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
            swal ( "Error", "Add/Update client failed !\n" + rdata.message, "error" );
          }
        }
      });

    } else {
      swal ( "Error", "Add/Update client failed !\n", "error" );
    }
  }

  openClean ( ) {
    this.setState ({ openClean: true });
  }

  closeClean ( ) {
    this.setState ({ openClean: false });
  }

  cleanClient ( ) {
    let rdata = this.state.client.pushData ( Config.API_URL_CLIENT_ERASE, this.state.row );
    if ( rdata ) {
      if ( rdata.status == "OK" ) {
        //this.handleEditClick ( );            
        swal ( "Success", "Successfully erased client !", "success", {
          buttons: false,
          timer: 2000,
        })
        .then((value) => {
          this.setState ( { saving : false } );
          this.props.clientUpdated ( );
          this.setState ( { somethingHasChanged: false } );
          this.setState ( { expanded: false } );
        });        
      } else {
        if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
        swal ( "Error", "Erase client failed !\n" + rdata.message, "error" );
      }
    }
    this.setState ({ openClean: false });
  }

  

  render() {

    let iref = "";
    let uIref = "";
    let firstName = "";
    let lastName = "";
    let email = "";
    let username = "";
    let password = "";
    let passwordv = "";
    let type = "";
    let status = 0;    
    let person = {};
    let userImage = noImage;
    let language_iref = "";
    let language_name = "";
    let language_image = "";
    let language_id = "";
    let erased = false;
    let uid = "";

    let createdOn = "";
    let modifiedOn = "";

    let userType = "";
    let status_image = noImage;
    let status_alt = "Unknown";

    let person_firstName = "";
    let person_lastName = "";    
    let person_email = "";    
    let person_phone = ""; 
    let person_birthday = "";    
    let person_city = "";
    let person_county = "";
    let person_country = "";
    let location_reg = "";

    let personAddress = {}
    let personAddress_name = "";
    let personAddress_postalCode = undefined;
    let personAddress_street = "";
    let personAddress_number = undefined;
    let personAddress_entrance = "";
    let personAddress_local = "";
    let personAddress_floor = undefined;
    let personAddress_complement = "";
    let personAddress_gps_lt = "0";
    let personAddress_gps_lg = "0";
    let personAddress_city = "";
    let personAddress_county = "";
    let personAddress_country = "";

    let person_gps = {
      "lt": 0.0,
      "lg": 0.0
    };

    let credit = 0.0;
    let family = {
      name: "",
      credit: 0.0,
      showCredit: false,
      show: false
    };
    let clientPro = {
      name: "",
      credit: 0.0,
      showCredit: false,
      show: false
    };

    let hasPerson = false; 
    if ( this.state.row ) {
      let rowC = this.state.row;
      if ( rowC.iref ) iref = rowC.iref;
      if ( rowC.credit ) credit = thousandsSeparator ( rowC.credit );
      if ( rowC.erased === true || rowC.erased === 1 ) erased = rowC.erased;
      if ( rowC.uid ) uid = rowC.uid;
      if ( rowC.createdOn ) createdOn = "Created: " + rowC.createdOn;
      if ( rowC.modifiedOn ) modifiedOn = "Modified: " + rowC.modifiedOn;
      if ( rowC.clientFamily_client ) {
        family.show = true;
        if ( rowC.clientFamily_client.canUseFamilyCredit ) {
          family.showCredit = true;          
        }
        if ( rowC.clientFamily_client.family ) {
          family.name = rowC.clientFamily_client.family.name;
          family.credit = thousandsSeparator ( rowC.clientFamily_client.family.credit );
        } else {
          family.show = false;
        }
      }
      

      if ( rowC.clientProfessional_client ) {
        clientPro.show = true;
        if ( rowC.clientProfessional_client.canUseCompanyCredit ) {
          clientPro.showCredit = true;          
        }
        if ( rowC.clientProfessional_client.clientPro ) {
          clientPro.name = rowC.clientProfessional_client.clientPro.name;
          clientPro.credit = thousandsSeparator ( rowC.clientProfessional_client.clientPro.credit );
        } else {
          clientPro.show = false;
        }
      }

      if ( rowC.user ) {
        let row = rowC.user;
        
        if ( row.iref ) uIref = row.iref;
        if ( row.first_name ) firstName = row.first_name;
        if ( row.last_name ) lastName = row.last_name;
        if ( row.email ) email = row.email;
        if ( row.username ) username = row.username;
        if ( row.password ) password = row.password;
        if ( row.user_type ) type = row.user_type;
        if ( row.user_status !== undefined ) status = parseInt ( row.user_status );
        userType = BackOfficeConfig.USER_TYPES [ type ];

        switch ( status ) {
          case 0:
            status_image = registered;
            status_alt = "Registered";
            break;      
          case 1:
            status_image = active;
            status_alt = "Active";
            break;
          case 2:
            status_image = discontinued;
            status_alt = "Discontinued";
            break;
        }

        if ( row.person ) {
          let person = row.person;

          if ( person.image ) {
            if ( 'iref' in person ) hasPerson = true;

            let iextension = person.image.slice(person.image.lastIndexOf("."));
            let ifilename = person.image.split('.').slice(0, -1).join('.');
            userImage = ifilename +  "_m" + iextension;  
            /* let fileName = userImage.replace(/\.[^/.]+$/, "")
            let fileExtensions = userImage.split(".").pop();
            userImage = fileName + "_m." + fileExtensions; */
          }

          if ( person.firstName ) person_firstName = person.firstName;
          if ( person.lastName ) person_lastName = person.lastName;
          if ( person.email ) person_email = person.email;
          if ( person.phone ) person_phone = person.phone;
          if ( person.birthday ) person_birthday = person.birthday;
    
          if ( person.entrance ) person += ", intrare " + person.entrance;
          if ( person.local ) person += ", local " + person.local;
          if ( person.floor ) person += ", etaj " + person.floor;
          if ( person.complement ) person += ", complement " + person.complement;
          if ( person.city ) {
            person_city = person.city.name; 
            if ( person.city.county ) {
              person_county = person.city.county.name;
              if ( person.city.county.country )
                person_country = person.city.county.country.name;
            }
          }
          if ( person.gps_lt ) person_gps [ "lt" ] = person.gps_lt;
          if ( person.gps_lg ) person_gps [ "lg" ] = person.gps_lg;

          if ( row.person.address ) {          
            personAddress = row.person.address;
            if ( personAddress.name ) personAddress_name = personAddress.name;
            if ( personAddress.postalCode ) personAddress_postalCode = personAddress.postalCode;
            if ( personAddress.street ) personAddress_street = personAddress.street;
            if ( personAddress.number ) personAddress_number = personAddress.number;
            if ( personAddress.entrance ) personAddress_entrance = personAddress.entrance;
            if ( personAddress.local ) personAddress_local = personAddress.local;
            if ( personAddress.floor ) personAddress_floor = personAddress.floor;
            if ( personAddress.complement ) personAddress_complement = personAddress.complement;
            if ( personAddress.gps_lt ) personAddress_gps_lt = personAddress.gps_lt;
            if ( personAddress.gps_lg ) personAddress_gps_lg = personAddress.gps_lg;
            if ( personAddress.city ) personAddress_city = personAddress.city.name;
            if ( personAddress.city.county ) personAddress_county = personAddress.city.county.name;
            if ( personAddress.city.county.country ) personAddress_country = personAddress.city.county.country.name;
          }
        }

        if ( row.language ) language_iref = row.language;
      }
    }
    let userFullName = firstName + " " + lastName;
    let personFullName = person_firstName + " " + person_lastName;

    let personsSearch = [ ];
    if ( this.state.personsSearch ) personsSearch = this.state.personsSearch;

    if ( this.state.newUser && password === "" && passwordv === "" ) {
      password = this.generateRandom ( 12, true );
      passwordv = password;
      let uRow = this.state.row;
      uRow.password = password;
      uRow.passwordv = password;
      
    }

    let showSave = false;
    if ( this.state.edit && this.state.somethingHasChanged )
      showSave = true;  
      
    let languages = [];
    if ( this.state.languages ) {
      languages = this.state.languages;
      Object.keys(languages).forEach(key => {
        let fData = languages [ key ];
        if ( fData.iref === language_iref ) {
          language_id = key;
          language_image = fData.image;
          language_name = fData.languageName;
        }
      });
    }

    let showLoading = false;
    if ( this.state.saving === true ) showLoading = true;

    let onlyClient = false;
    if ( this.props.onlyClient ) onlyClient = this.props.onlyClient;

    let userTypes = {};
    //console.log ( BackOfficeConfig.USER_TYPES );
    Object.keys(BackOfficeConfig.USER_TYPES).forEach(key => {
      if ( onlyClient ) {
        if ( parseInt(key) == 5 ) {
          let obj = { };
          obj [ key ] = BackOfficeConfig.USER_TYPES[key];
          userTypes = Object.assign ( userTypes, obj );
        }
      } else {
        if ( parseInt(key) > 5 ) {
          let obj = { };
          obj [ key ] = BackOfficeConfig.USER_TYPES[key];
          userTypes = Object.assign ( userTypes, obj );
        }
      }
      
    });

    let usersSearch = [ ];
    if ( this.state.usersSearch ) usersSearch = this.state.usersSearch;

    let openClean = false;
    if ( this.state.openClean ) openClean = true;

    return (
      /* , display: 'flex' */
    <Card sx={{ borderRadius: '16px', m: 1 }}>

      <Box sx={{ display: 'block', flexDirection: 'row', width: "100%" }}>
        <CardHeader
          avatar={          
            <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                <Avatar alt={status_alt} src={status_image} title={status_alt}>
                </Avatar>  
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
              <Typography variant="subtitle1" color="text.secondary">
                {iref}
              </Typography>
              </Box>
            </Box>                    
          }        
          action={            
            <IconButton aria-label="settings" onClick={this.handleEditClick}>
              <EditIcon
                sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}                
              />
            </IconButton>
          }
          title={userFullName}          
          titleTypographyProps={{variant:'h5' }}
          subheader={userType}
        />
      </Box>

      {/* <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
        <Box sx={{ display: 'block', flexDirection: 'column', width: '20%'}}>          
        </Box>        
        <Box sx={{ display: 'block', flexDirection: 'column', display: "block", width: '30%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>              
          </Box> 
        </Box>
        <Box sx={{ display: 'block', flexDirection: 'column', justifyContent:"right", textAlign: "end", width: '50%' }}>
        </Box>
      </Box> */}

      <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
        <Box sx={{ display: 'block', flexDirection: 'column', width: '20%'}}>
          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
            <CardMedia
              component="img"
              height="120"
              sx={{ width: 128, marginLeft: 1 }}
              image={userImage}
              alt={userFullName}
              title={userFullName}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "start", justifyContent:"left", marginLeft: 1 }}>           
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "start", justifyContent:"left", marginLeft: 1 }}>
              {person_birthday}
            </Box>            
          </Box>          
        </Box>

        <Box sx={{ display: 'block', flexDirection: 'column', display: "block", width: '30%' }}>
          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
            { this.state.edit ?
              <Autocomplete
                disablePortal
                id="user"
                name="User"
                options={usersSearch}
                sx={{ width: "460px", marginTop: 1 }}
                onChange={this.userChange}
                renderInput = {
                  (params) =>
                    <TextField 
                      {...params}
                      label="User (type keywords)"
                      onChange={this.userSearch}
                    />}
              />
              : username
            }
          </Box>
          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "start", justifyContent:"left", marginTop: 1 }}>
            Credit:
            <Chip label={credit} color="success" sx={{ width: "100px", marginLeft: 1}}/>
          </Box>
          {family.show ?
            <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "start", justifyContent:"left", marginTop: 1 }}>
              Family: {family.name}
              {family.showCredit ?
                <Chip label={family.credit} color="primary" sx={{ width: "100px", marginLeft: 1}}/>
                : ""
              }            
            </Box>
            : ""
          }
          {clientPro.show ?
            <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "start", justifyContent:"left", marginTop: 1 }}>
              Company: {clientPro.name}
              {clientPro.showCredit ?
                <Chip label={clientPro.credit} color="error" sx={{ width: "100px", marginLeft: 1}}/>
                : ""
              }            
            </Box>
            : ""
          }
          
        </Box>

        

        <Box sx={{ display: 'block', flexDirection: 'column', justifyContent:"right", textAlign: "end", width: '50%' }}>

          <Box sx={{ display: 'inline-flex', flexDirection: 'row', textAlign: "end", justifyContent:"rigth", marginRight: "5px" }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "start", justifyContent:"left" }}>
              {email}              
            </Box>      
          </Box>

          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "end", justifyContent:"rigth", marginRight: "5px" }}>
            {person_email}          
          </Box>
          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "end", justifyContent:"rigth", marginRight: "5px" }}>
            {person_phone}
          </Box>
          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "end", justifyContent:"rigth", marginTop: 1 }}>
            {createdOn}<br/>{modifiedOn}
          </Box>

        </Box>
      </Box>         
          
      <Box sx={{ flexDirection: 'row', width: '100%', display: 'flex' }}>
        <Box sx={{ flexDirection: 'column', justifyContent:"left", width: '50%', marginLeft: 1 }}>
          <CardActions disableSpacing sx={{display: "flex", textAlign: "begining" }}>
            {!erased ? 
              <CleaningServicesIcon color="error" sx={{color: "error"}} onClick={this.openClean}/>
              : uid
            }
          
          </CardActions>
        </Box>
        <Box sx={{ flexDirection: 'column', justifyContent:"right", width: '50%' }}>
          <CardActions disableSpacing sx={{display: "block", textAlign: "end" }}>       
          <ExpandMore
            expand={this.state.expanded}
            onClick={this.handleExpandClick}
            aria-expanded={this.state.expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
          </CardActions>
        </Box>        
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit sx={{width: "100% !important"}}>
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <b>{personFullName}</b>
          </Box>        
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            {personAddress_name}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>            
            {personAddress_street}
            {personAddress_number ? ", nr. " + personAddress_number : ""}
            {personAddress_floor ? ", et. " + personAddress_floor : "" }
            {personAddress_postalCode ? ", " + personAddress_postalCode : ""}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>            
            {personAddress_city}, {personAddress_county}, {personAddress_country}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>            
            {personAddress_gps_lg}, {personAddress_gps_lt}
          </Box>
          

          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "middle", justifyContent:"center", alignItems: "center", paddingTop: "8px" }}>
            { /*<Typography>Employees</Typography>*/}
            <Table sx={{ minWidth: 700, textAlign: "middle", justifyContent:"center" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Image</StyledTableCell>
                  <StyledTableCell align="left">First Name</StyledTableCell>
                  <StyledTableCell align="left">Last Name</StyledTableCell>            
                  <StyledTableCell align="left">Phone</StyledTableCell>            
                  <StyledTableCell align="left">Email</StyledTableCell>
                  <StyledTableCell align="left">Birthday</StyledTableCell>
                  <StyledTableCell align="left">Username</StyledTableCell>
                  <StyledTableCell align="left">Card</StyledTableCell>
                  <StyledTableCell align="left">Level</StyledTableCell>
                  {/* <StyledTableCell align="right">Action</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {allData} */}
              </TableBody>
            </Table>
            
          </Box>



          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "end", justifyContent:"right", alignItems: "right", paddingTop: "8px" }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "end", justifyContent:"right", alignItems: "right", paddingTop: "8px" }}> 
              { showLoading ?
                <CircularProgress
                size={24}
                sx={{
                  color: "#1e2833",
                  position: 'relative',
                  top: 0,
                  left: 0,
                  zIndex: 99,
                }}
                />
              :
              ""            
              }
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: "end", justifyContent:"right", alignItems: "right", paddingTop: "8px" }}> 
            { showSave ?
              <Button
                variant="contained"
                size="small"
                sx={{ color: "#e3e3e5" }}
                startIcon={<SaveIcon />}
                onClick={this.pushData}
              >
                Save
              </Button>
              : ""
            }
            </Box>
          </Box>
          
          </CardContent>

        </Collapse>

      </Box>


      <Dialog
        open={openClean}
        onClose={this.closeClean}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Erase Client
        </DialogTitle>
        <DialogContent>
          <DialogContentText color="error">
            Important: Erasing implies removing all the data related to this client !
          </DialogContentText>
          <DialogContentText>
            Including: User, Person and messages.<br/>
            Transactions and other accounting data will not be altered.
          </DialogContentText>
          <DialogContentText>
            Any account associated with this client will be deactivated !
          </DialogContentText>          
          
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.closeClean}>
            Cancel
          </Button>
          <Button onClick={this.cleanClient}>ERASE</Button>
        </DialogActions>
      </Dialog>

    </Card>
   
    );

  }
}

export default ClientAccount;
