import React from 'react';
import { Avatar, Card, CardContent, Stack, SvgIcon, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import GroupIcon from '@mui/icons-material/Group';
import MainCard from './MainCard';

import Client from './../Client';
import Config from './../ClientConfig';

function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
class OverviewTotalCustomers extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      tclients: [],
      filterData: {}
    };

    //this.applyFilters = this.applyFilters.bind(this);    

  }

  componentDidMount() {
    let queryData = { "type": 1 }
    let data = this.state.client.getData ( Config.API_URL_REPORTS_CLIENTS, queryData );
    if ( data ) {
      this.setState ( { tclients: data });
    }
    this.setState ( { filterData: queryData } );
  }

  render() {
    let clientsDataT = 0;
		let clientsDataLM = 0;
		let difference = 0;
		let dif_sign = "";
		let dif_positive = false;

    if ( this.state.tclients ) {
      let tclients = this.state.tclients;
      if ( tclients.Total_clienti !== undefined ) clientsDataT = parseInt(tclients.Total_clienti);
      if ( tclients.Total_clienti_LM !== undefined ) clientsDataLM = parseInt(tclients.Total_clienti_LM);
      if ( clientsDataLM != 0 ) {difference = parseInt((clientsDataT / clientsDataLM -1 )*100);
        }else {difference = 0;}
      dif_positive = (difference >= 0) ? true : false;
      dif_sign = (difference >= 0) ? "+" : ""; 
    }

    return (
      <Card sx={{ borderRadius:"16px", height: '100%' }}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography
              color="text.secondary"
              variant="overline"
            >
              Total Clients
            </Typography>
            <Typography variant="h4">
              { (clientsDataT>1000) ? thousandsSeparator((clientsDataT/1000).toFixed(0))+"K" : thousandsSeparator((clientsDataT).toFixed(0)) }
            </Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: 'success.main',
              height: 56,
              width: 56
            }}
          >
            <SvgIcon>
            <GroupIcon />
            </SvgIcon>
          </Avatar>
        </Stack>
        {dif_positive && (
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{ mt: 2 }}
          >
            <Stack
              alignItems="center"
              direction="row"
              spacing={0.5}
            >
              <SvgIcon
                color={dif_positive ? 'success' : 'error'}
                fontSize="small"
              >
              {dif_positive ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
              }
              </SvgIcon>
              <Typography
                color={dif_positive ? 'success.main' : 'error.main'}
                variant="body2"
              >
                {thousandsSeparator((difference).toFixed(0))}%
              </Typography>
            </Stack>
            <Typography
              color="text.secondary"
              variant="caption"
            >
              vs. last month
            </Typography>
          </Stack>
        )}
      </CardContent>
    </Card>
    );

  }
}

export default OverviewTotalCustomers;