import React from 'react';
import { Avatar, Box, Card, CardContent, CardHeader, Stack, SvgIcon, Typography, Button } from '@mui/material';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';

import Chart from './chart';

import Client from '../Client';
import Config from '../ClientConfig'; 
  
class ClientsByAge extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      ages: {},
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);

  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    let queryData = { "type": 1 }
    let data = this.state.client.getData ( Config.API_URL_REPORTS_DEMOGRAPHIC, queryData );
    if ( data ) {
      this.setState ( { ages: data });
    }
    this.setState ( { filterData: queryData } );
  }

  doSync ( ) {
    this.getData ( );
  }

  render() {

		//let ageLabels = [];
		//let ageData = [];
    let agesData = [];

    if (this.state.ages){
      Object.keys(this.state.ages).forEach(key => {
        let fData = this.state.ages [ key ];			
        //ageLabels.push ( key );
        //ageData.push ( fData ); 
        let counter = 0;
        let myData = {'x': key, 'y': (parseInt(fData))};
        if (counter<10){
          agesData.push ( myData );
        }
        counter += 1;
      });
    }

    return (
        <Card style={{ height: '500px' }} sx={{ borderRadius:"16px", height: '100%' }}>
          <CardHeader title="Clients By Ages" />
          <CardContent>
            <Chart
              height={350}
              type="bar2v"
              series={[
                {	
                  name: 'Clients Age',
                  data: agesData
                }
              ]}
              width="100%"
            />
          </CardContent>
        </Card>
    );

  }
}

export default ClientsByAge;