import * as React from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import MenuIcon from '@mui/icons-material/Menu';

class SmallMenu extends React.Component {

    constructor(props) {
      super(props);
  
      this.state = {
        menuOpenState: false,
        anchorReference: 'anchorEl'
      };

      this.toggleOpenMenu = this.toggleOpenMenu.bind(this);
    }
  
    componentDidMount() {
    }
  
    componentDidUpdate() { 
      //window.removeEventListener('load', this.handleLoad)  
    } 
  
    toggleOpenMenu ( ) {
      let menuState = this.state.menuOpenState;
      this.setState ( {menuOpenState: !menuState} );
      this.props.toggleSmallMenu (!menuState);
    }
  

    render() {
    return (
        <Stack direction="row" spacing={2}>
        <div>
            <Button
            id="composition-button"
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={this.toggleOpenMenu}
            >
            <MenuIcon />
            </Button>
        </div>
        </Stack>
    );
        }
}

export default SmallMenu;