import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import OverviewProductsProgress from './components/biReports/OverviewProductsProgress';
import OverviewTotalCustomers from './components/biReports/OverviewTotalCustomers';
import OverviewDemographic from './components/biReports/OverviewDemographic';
import OverviewLatestCustomers from './components/biReports/OverviewLatestCustomers';
import ClientsByAge from './components/biReports/ClientsByAge';
import ClientsByUrban from './components/biReports/ClientsByUrban';
import ClientsByCounty from './components/biReports/ClientsByCounty';

import Client from './components/Client';
import Config from './components/ClientConfig';

const now = new Date();
class RapClients extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      /* locationGroups: null, */
      edit: false,
      /* 
	  byCounty: {} */
    };

 
  }

  componentDidMount() {    
	/* 
	queryData = { "type": 0 }
	data = this.state.client.getData ( Config.API_URL_REPORTS_GEOGRAPHIC, queryData );
    if ( data ) {
      this.setState ( { byCounty: data });
    }*/
  }

  componentWillUnmount() {    
  }

  /* setLocationsGroups ( groups ) {
    let a = { }
    if ( groups !== null && groups !== undefined ) {
      groups.map ( (locationGroup) => {
        a [ locationGroup.iref ] = locationGroup;        
      });
      this.setState ( { locationGroups : a });
    }
  } */

  
  render() {
		/* 
		let countyLabels = [];
		let countyData = [];
		Object.keys(this.state.byCounty).forEach(key => {
			let fData = this.state.byCounty [ key ];			
			countyLabels.push ( key );
			countyData.push ( fData );
			//counties [ fData.iref ] = fData.name;      
		  }); */

		return (
			<Box component="main" sx={{flexGrow: 1, py: 8, paddingTop: "30px", paddingBottom: "30px"}}>
			<Container style={{paddingLeft: 5, paddingRight:5}}  maxWidth="xl">
			  <Grid container spacing={3}>
				<Grid item xs={12} sm={6} lg={3}>
					<OverviewTotalCustomers />
				</Grid>
				<Grid item xs={12} sm={6} lg={3}>
					<OverviewProductsProgress />
				</Grid>
				<Grid item xs={12} sm={6} lg={3}>
					<OverviewTotalCustomers />
				</Grid>
				<Grid item xs={12} sm={6} lg={3}>
					<OverviewProductsProgress />
				</Grid>
				<Grid item xs={12} md={6} lg={6}>
					<ClientsByAge />
				</Grid>
				<Grid item xs={12} md={6} lg={6}>
					<ClientsByUrban />
				</Grid>
				<Grid item xs={12} md={6} lg={12}>
					<ClientsByCounty />
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<OverviewDemographic />
				</Grid>
				<Grid item xs={12} md={12} lg={8}>
					<OverviewLatestCustomers />
				</Grid>
			  </Grid>
			</Container>
		  </Box>
		);
	}
}

export default RapClients;
