import React from 'react';
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, Stack, SvgIcon, Typography, Button } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';

import Chart from './chart';

import Client from '../Client';
import Config from '../ClientConfig';

function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  
class SalesYTDPosChart extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      salespos_list: [],
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);
    
  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    let queryData = { "type": 3 }
	  let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { salespos_list: data });
    }
    this.setState ( { filterData: queryData } );
  }

  doSync ( ) {
    this.getData ( );
  }
  
  render() {

    let posSalesData = [];
    let posSalesList = [];
    if (this.state.salespos_list){
      if (this.state.salespos_list.result) {
        let counter = 0;
        Object.keys(this.state.salespos_list.result).forEach(key => {
          let fData = this.state.salespos_list.result [ key ];
          let myData = {'x': fData.name, 'y': (parseInt(fData.value/1000)+ "K")};
          let p = {
            id: counter+1,
            name: fData.name,
            value: thousandsSeparator(parseInt(fData.value))
          }
          if (counter<10){
            posSalesData.push ( myData );
            posSalesList.push ( p );
          }
          counter += 1;
        });
      }
    } 

    return (
      <Card style={{ height: '500px' }} sx={{ borderRadius:"16px", height: '100%' }}>
      <CardHeader
        action={(
          <Button
            color="inherit"
            size="small"
            startIcon={(
              <SvgIcon fontSize="small">
                <AutorenewIcon />
              </SvgIcon>
            )}
          >
            Sync
          </Button>
        )}
        title="Sales MTD"
      />
      <CardContent>
        <Chart
          height={350}
          type="bar2k"
          series={[
            {	
              name: 'Sales YTD',
              data: posSalesData
            }
          ]}
          width="100%"
        />
      </CardContent>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          color="inherit"
          endIcon={(
            <SvgIcon fontSize="small">
              <ArrowForwardIcon />
            </SvgIcon>
          )}
          size="small"
        >
          Overview
        </Button>
      </CardActions>
    </Card>
    );

  }
}

export default SalesYTDPosChart;