import React from 'react';
import { Avatar, Box, Card, CardContent, CardHeader, Stack, SvgIcon, Typography, Button } from '@mui/material';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';

import Chart from './chart';

import Client from '../Client';
import Config from '../ClientConfig';


export const iconMap = {
  Male: (
    <SvgIcon>
      <ManIcon color="success" />
    </SvgIcon>
  ),
  Female: (
    <SvgIcon>
      <WomanIcon color="secondary"/>
    </SvgIcon>
  ),
  Undefined: (
    <SvgIcon>
      <MoreHorizIcon color="warning" />
    </SvgIcon>
  ),
  'Not Specified': (
    <SvgIcon>
      <NotInterestedIcon color="primary"/>
    </SvgIcon>
  )
};  
  
class OverviewDemographic extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      genders: [],
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);

  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    let queryData = { "type": 0 }
    let data = this.state.client.getData ( Config.API_URL_REPORTS_DEMOGRAPHIC, queryData );
    if ( data ) {
      this.setState ( { genders: data });
    }
    this.setState ( { filterData: queryData } );
  }

  doSync ( ) {
    this.getData ( );
  }

  render() {

    let genderLabels = [];
		let genderData = [];

    if ( this.state.genders ) {
      Object.keys(this.state.genders).forEach(key => {
        let fData = this.state.genders [ key ];			
        genderLabels.push ( key );
        genderData.push ( fData );
        });
    }

    return (
      <Card sx={{ borderRadius:"16px", height: '100%' }}>
        <CardHeader title="Clients Demographic" />
        <CardContent>
          <Chart
            height={300}
            type="donut"
            series={genderData}
            labels={genderLabels}
            width="100%"
          />
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            spacing={2}
            sx={{ mt: 2 }}
          >
            {genderData.map((item, index) => {
              const label = genderLabels[index];

              return (
                <Box
                  key={label}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  {iconMap[label]}
                  {/* <Typography
                    sx={{ my: 1 }}
                    variant="h6"
                  >
                    {label}
                  </Typography> */}
                  {/* <Typography
                    color="text.secondary"
                    variant="subtitle2"
                  >
                    {item}
                  </Typography> */}
                </Box>
              );
            })}
          </Stack>
        </CardContent>
      </Card>
    );

  }
}

export default OverviewDemographic;


 {/* <Button
                color="inherit"
                size="small"
                startIcon={(
                <SvgIcon fontSize="small">
                    <AutorenewIcon />
                </SvgIcon>
                )}
                onClick={() => this.doSync()}
            /> */}