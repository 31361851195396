import React from 'react';
import { Avatar, Card, CardContent, Stack, SvgIcon, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import GroupIcon from '@mui/icons-material/Group';
import MainCard from './MainCard';
import { format, intervalToDuration } from 'date-fns';

import Client from '../Client';
import Config from '../ClientConfig';

function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

let d = new Date();
let today = format(d, 'yyyy-MM-dd');  // mm=week, MM=month
let yesterday = format(d.setDate(d.getDate() - 1), 'yyyy-MM-dd');

class OverviewTotalOrders extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      torders: [],
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);

  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    let queryData = {
      "type": 23
     }
	let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { torders: data });
    }
    this.setState ( { filterData: queryData } );
  }

  doSync ( ) {
    this.getData ( );
  }
  
  render() {
    let ordersDataT = 0;
		let ordersDataLM = 0;
		let difference = 0;
		let dif_sign = "";
		let dif_positive = false;

    if ( this.state.torders ) {
      let torders = this.state.torders;
      if ( torders.Total_comenzi !== undefined ) ordersDataT = parseInt(torders.Total_comenzi);
      if ( torders.Total_comenzi_LM !== undefined ) ordersDataLM = parseInt(torders.Total_comenzi_LM);
      if ( ordersDataLM != 0 ) {difference = parseInt((ordersDataT / ordersDataLM -1 )*100);
        }else {difference = 0;}
      dif_positive = (difference >= 0) ? true : false;
      dif_sign = (difference >= 0) ? "+" : ""; 
    }

    return (
      <Card sx={{ borderRadius:"16px", height: '100%' }}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography
              color="text.secondary"
              variant="overline"
            >
              Total orders
            </Typography>
            <Typography variant="h4">
              { (ordersDataT>1000) ? thousandsSeparator((ordersDataT/1000).toFixed(1))+"K" : thousandsSeparator((ordersDataT).toFixed(0)) }
            </Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: 'success.main',
              height: 56,
              width: 56
            }}
          >
            <SvgIcon>
            <GroupIcon />
            </SvgIcon>
          </Avatar>
        </Stack>
        {dif_positive && (
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{ mt: 2 }}
          >
            <Stack
              alignItems="center"
              direction="row"
              spacing={0.5}
            >
              <SvgIcon
                color={dif_positive ? 'success' : 'error'}
                fontSize="small"
              >
              {dif_positive ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
              }
              </SvgIcon>
              <Typography
                color={dif_positive ? 'success.main' : 'error.main'}
                variant="body2"
              >
                {thousandsSeparator((difference).toFixed(0))}%
              </Typography>
            </Stack>
            <Typography
              color="text.secondary"
              variant="caption"
            >
              vs. last month
            </Typography>
          </Stack>
        )}
      </CardContent>
    </Card>
    );

  }
}

export default OverviewTotalOrders;
