import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser } from "react-icons/fa";
import Skeleton from '@mui/material/Skeleton';

//import './css/sideBar.scss';
import './css/elements.css';
import './css/dashboard.css';

import Client from './components/Client';
import Config from './components/ClientConfig';
//import Product from './components/Product';
import Location from './components/Location';
import LocationsFilters from './components/LocationsFilters';



let theme = createTheme();

class Locations extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      locations: null,
      locationsGroups: null,
      addNewLocation: false,
      filterData: {},
      addNewButton: false,
    };

    const { loading = false } = props;

    this.applyFilters = this.applyFilters.bind(this);    
    this.toggleAdd = this.toggleAdd.bind(this);
    this.getData = this.getData.bind(this);
    this.locationUpdated = this.locationUpdated.bind(this);
  }

  getData ( ) {
    let queryData = this.state.filterData;
    queryData [ "limit" ] = 20;
    queryData [ "orderBy_modifiedOn" ] = true;
    
    let data = this.state.client.getLocations ( queryData );
    this.setState ( { locations: data.locations });

    queryData = {}
    queryData [ "status" ]= 1;
    let gdata = this.state.client.getData ( Config.API_URL_LOCATIONSGROUPS, queryData );
    this.setState ( { locationsGroups: gdata.locationsGroups });
  }

  componentDidMount() {
    //this.handleProfile ( );
    /* let queryData = {
      "status" : 2
    } */
    this.getData ( );
  }

  locationUpdated ( ) {
    //this.toggleAdd ( );
    this.setState ( { addNewButton: false } );
    this.getData ( );
    window.scrollTo(0, 0);
    this.setState ( {addNewLocation: false} );
    this.setState ( {addNewButton: false} );
  }

  componentWillUnmount() {    
  }

  //Function to change the main Products filter state
  applyFilters ( queryData ) {
    //console.log ( "+++++++++++++++++++++++++++");
    //console.log ( queryData );
    if ( queryData ) {
      queryData [ "limit" ] = 20;
      queryData [ "orderBy_modifiedOn" ] = true;
      ////console.log ( queryData );
      let data = this.state.client.filterLocations ( queryData );
      this.setState ( { locations: data.locations });
      this.setState ( { filterData: queryData });
    }
  }

 
  toggleAdd ( ) {
    let doAdd = this.state.addNewLocation;
    this.setState ( {addNewLocation: !doAdd} );
    this.setState ( {addNewButton: !doAdd} );
  }

  render() {

    let geoData = {};
    if ( this.props.geoData ) geoData = this.props.geoData;
    //let aa = Object.entries ( geoData );

    let countries = {};
    let counties = {};
    Object.keys(geoData).forEach(key => {
      let fData = geoData [ key ];
      countries [ key ] = fData.name;
      //console.log ( fData );
      Object.keys ( fData ).forEach( fKey => {
        if ( fKey !== "name" ) counties [ fKey ] = fData [ fKey ].name;
      });
    });

    //console.log ( this.state.locations );
    let locationsList;
    ////console.log ( this.state.locations );
    let locationsCounter = 0;
    if ( this.state.locations ) {
      locationsCounter = this.state.locations.length;
      locationsList = this.state.locations.map ( (location) => {
        return <Location
          key={location.iref}
          location={location}
          //image={locationImage}
          geoData = {this.props.geoData}
          countries = {countries}
          locationUpdated = {this.locationUpdated}
        />
      });
    }

    let groups = {};
    let locationsGroups = {};
    if ( this.state.locationsGroups ) {
      locationsGroups = this.state.locationsGroups;
      Object.keys(locationsGroups).forEach(key => {
        let fData = locationsGroups [ key ];
        groups [ fData.iref ] = fData.name;
      });
    }    

    return (

 
      <div style={{ backgroundColor: "#273442", height: "100%" }}>
        <div className='sticky'>

          <LocationsFilters
            applyFilters = {this.applyFilters}
            applyCategory = {this.applyCategory}
            toggleAdd = {this.toggleAdd}
            locationsCounter = {locationsCounter}
            countries = {countries}
            counties = {counties}
            locationsGroups={groups}
            sx={{ position: 'sticky', top: 0}}
            addNew = {this.state.addNewButton}
          />

        </div>

        { this.state.addNewLocation ?
        <Location
          key={0}
          location={{}}
          //image={{}}
          geoData = {this.props.geoData}
          countries = {countries}
          locationUpdated = {this.locationUpdated}
        />
        : ""
        }

        {this.loading ? <Skeleton animation="wave" variant="rounded" width={800} height={400} /> : locationsList }

      </div>

    );

  }
}

export default Locations;