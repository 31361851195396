import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser } from "react-icons/fa";
import Skeleton from '@mui/material/Skeleton';

//import './css/sideBar.scss';
import './css/elements.css';
import './css/dashboard.css';

import Client from './components/Client';
import Config from './components/ClientConfig';
//import Product from './components/Product';
import Employee from './components/Employee';
import EmployeesFilters from './components/EmployeesFilters';



let theme = createTheme();

class Employees extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      employees: [],
      locationsGroups: [],
      addNewEmployee: false,
      addNewButton: false,
      filterData: {}
    };

    const { loading = false } = props;

    this.applyFilters = this.applyFilters.bind(this);    
    this.toggleAdd = this.toggleAdd.bind(this);
    this.employeeUpdated = this.employeeUpdated.bind(this);
    this.getData = this.getData.bind(this);
  }

  getData () {
    this.setState ( { employees: [] });
    let queryData = this.state.filterData;
    queryData [ "limit" ] = 20;
    queryData [ "orderBy_modifiedOn" ] = true;
    let data = this.state.client.getData ( Config.API_URL_EMPLOYEES, queryData );
    this.setState ( { employees: data.employees });
    queryData [ "status" ]= 1;
    let gdata = this.state.client.getData ( Config.API_URL_LOCATIONSGROUPS, queryData );
    this.setState ( { locationsGroups: gdata.locationsGroups });
  }

  componentDidMount() {
    this.getData ( );
  }

  componentWillUnmount() {    
  }

  //Function to change the main Products filter state
  applyFilters ( queryData ) {
    //console.log ( "+++++++++++++++++++++++++++");
    //console.log ( queryData );
    if ( queryData ) {
      ////console.log ( queryData );
      let data = this.state.client.filterEmployees ( queryData );
      this.setState ( { employees: data.employees });
      this.setState ( { filterData: queryData });
    }
  }

  toggleAdd ( ) {
    let doAdd = this.state.addNewEmployee;
    this.setState ( {addNewEmployee: !doAdd} );
    this.setState ( {addNewButton: !doAdd} );
  }

  employeeUpdated ( ) {
    //this.toggleAdd ( );
    this.setState ( { addNewButton: false } );
    this.getData ( );
    window.scrollTo(0, 0);
    this.setState ( {addNewEmployee: false} );
    this.setState ( {addNewButton: false} );
  }

  render() {
    //console.log ( this.state.locations );
    let employeesList;
    ////console.log ( this.state.employees );
    let employeesCounter = 0;
    if ( this.state.employees ) {
      employeesCounter = this.state.employees.length;
      employeesList = this.state.employees.map ( (employee) => {
        ////console.log ( employee );
        let employeeImage = "";        
        if ( employee.person  ) {
            if ( employee.person.image ) employeeImage = employee.person.image;
        }

        let emp = {};
        emp = employee;
        emp.image = employeeImage;
        emp.locations = employee.employeeLocation_employee;   

        return <Employee
          key={employee.iref}
          employee={emp}
          employeeUpdated={this.employeeUpdated}
        />
      });
    }

    ////console.log ( productsList);

    let geoData = {};
    if ( this.props.geoData ) geoData = this.props.geoData;
    //let aa = Object.entries ( geoData );

    let countries = {};
    let counties = {};
    Object.keys(geoData).forEach(key => {
      let fData = geoData [ key ];
      countries [ key ] = fData.name;
      //console.log ( fData );
      Object.keys ( fData ).forEach( fKey => {
        if ( fKey !== "name" ) counties [ fKey ] = fData [ fKey ].name;
      });
    });

    let groups = {};
    let locationsGroups = {};
    if ( this.state.locationsGroups ) {
      locationsGroups = this.state.locationsGroups;
      Object.keys(locationsGroups).forEach(key => {
        let fData = locationsGroups [ key ];
        groups [ fData.iref ] = fData.name;
      });
    }    

    return (

 
      <div style={{ backgroundColor: "#273442", height: "100%" }}>
        <div className='sticky'>

          <EmployeesFilters
            key = "ef0"
            applyFilters = {this.applyFilters}
            applyCategory = {this.applyCategory}
            toggleAdd = {this.toggleAdd}
            employeesCounter = {employeesCounter}
            countries = {countries}
            counties = {counties}
            locationsGroups={groups}
            sx={{ position: 'sticky', top: 0}}
            addNew = {this.state.addNewButton}
          />

        </div>

        { this.state.addNewEmployee ?
        <Employee
          key = "0"
          employee={{}}
          employeeUpdated={this.employeeUpdated}
        />
        : ""
        }

        {/* {this.loading ? <Skeleton animation="wave" variant="rounded" width={800} height={400} /> : employeesList } */}
        {employeesList}
      </div>

    );

  }
}

export default Employees;