import React from 'react';
import { alpha, Avatar, createTheme, AspectRatio, CardOverflow, Box, Card, Grid, CardActions, CardContent, CardHeader, Divider, Stack, SvgIcon, Typography, Button, TextField, Menu, MenuItem, Table, TableContainer, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import ViewListIcon from '@mui/icons-material/ViewList';
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import DropDown from '../DropDown';
import { format, intervalToDuration } from 'date-fns';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import Chart from './chart';

import Client from '../Client';
import Config from '../ClientConfig';
import BackOfficeConfig from '../../Config';

let theme = createTheme();

let d = new Date();
let today = format(d, 'yyyy-MM-dd');  // mm=week, MM=month
let yesterday = format(d.setDate(d.getDate() - 1), 'yyyy-MM-dd');

function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

function getStringLength(str) {
    return str.length;
  }

class OverviewOrders extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      date: yesterday,
      orders: [],
      filterData: {},
      value: "1"
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.queryData = { };
  }

  componentDidMount() {
    this.getData ();
  }

  getData ( ) {  
    let queryData = {
       "type": 22,
       "date": this.state.date }
    // console.log(queryData);
    let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
      if ( data ) {
        this.setState ( { orders: data });
      }
      this.setState ( { filterData: queryData } );
    // console.log(data); 
    }
  
  doSync ( ) {
    this.getData ( );
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  }

  render() {
    let hoursOrders = [];
    let hoursValue = [];
    let orders_today_value = [];
    let orders_today_trans = [];
    let orders_day_value = [];
    let orders_day_trans = [];

    if ( this.state.orders ) {
        let orders = this.state.orders;
        if (this.state.orders.Count_hours) {
          Object.keys(this.state.orders.Count_hours).forEach(key => {
            let fData = this.state.orders.Count_hours [ key ];
            let sign ="";
            if (fData.hour < 10) {sign = "_0"} else {sign = "_"};
            let ordersValue = {'x': fData.date+sign+fData.hour, 'y': fData.value};
            hoursValue.push ( ordersValue );
            let ordersCount = {'x': fData.date+sign+fData.hour, 'y': fData.trans};
            hoursOrders.push ( ordersCount );
          });
        }
        if (this.state.orders.orders_today) {
          Object.keys(this.state.orders.orders_today).forEach(key => {
            let fData = this.state.orders.orders_today [ key ];
            let myData = {'x': fData.hour, 'y': fData.value};
            orders_today_value.push ( myData );
            let myTrans = {'x': fData.hour, 'y': fData.trans};
            orders_today_trans.push ( myTrans );
          });
        } 
        if (this.state.orders.orders_day) {
          Object.keys(this.state.orders.orders_day).forEach(key => {
            let fData = this.state.orders.orders_day [ key ];
            let myData = {'x': fData.hour, 'y': fData.value};
            orders_day_value.push ( myData );
            let myTrans = {'x': fData.hour, 'y': fData.trans};
            orders_day_trans.push ( myTrans );
          });
        }   
    }

    let maxM_day = Math.max(...orders_day_value.map(({ y }) => y));
    let maxM_today = Math.max(...orders_today_value.map(({ y }) => y));
    let maxM_day_trans = Math.max(...orders_day_trans.map(({ y }) => y));
    let maxM_today_trans = Math.max(...orders_today_trans.map(({ y }) => y));
    let maxM = parseInt(Math.max(maxM_day,maxM_today));
    let p1 = maxM.toString().length -1;
    maxM = Math.ceil(maxM/10**p1 )*10**p1;
    let maxM_trans = parseInt(Math.max(maxM_day_trans,maxM_today_trans));
    let p2 = maxM_trans.toString().length -1;
    maxM_trans = Math.ceil(maxM_trans/10**p2 )*10**p2;

    return (
        <Card variant="outlined" style={{ height: '600px' }} sx={{ paddingTop:"6px", borderRadius:"16px", height: '100%' }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between'}}>
              <Tabs value={this.state.value} onChange={this.handleChange} aria-label="tabs example">
                <Tab style= {{}} icon={<BarChartIcon />} value="1" />
                <Tab classes={{ tab: {width: 50}, tabRoot: {width: 50}, root: {width: 50} }} label="Day" value="2" />
              </Tabs>  
          </Box>
        <Box>
            {this.state.value === "1" && (
                  <Chart
                  height={350}
                  type='line0'
                  series={[
                    {
                    name: 'Orders value',
                    type: 'bar',
                    data: hoursValue
                    },
                    {
                      name: 'Orders count',
                      type: 'line',
                      data: hoursOrders
                    }
                  ]}
                  yaxis={[
                    {
                      seriesName: 'Orders value',
                      axisTicks: {
                        show: true
                      },
                      axisBorder: {
                        show: true,
                      }/* ,
                      title: {
                        text: "Columns"
                      } */
                    },
                    {
                      seriesName: 'Orders count',
                      axisTicks: {
                        show: true
                      },
                      axisBorder: {
                        show: true,
                      }/* ,
                      title: {
                        text: "Line"
                      } */
                    }
                  ]}
                  width={"100%"}
                />
            )}
            {this.state.value === "2" && (
              <Chart
              height={350}
              type="line11"
              options={{
                chart: {
                  // type: 'bar',
                  background: 'transparent',
                  stacked: false,
                  toolbar: {
                    show: false,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                      download: true,
                      selection: true,
                      zoom: true,
                      zoomin: true,
                      zoomout: true,
                      pan: true,
                      reset: true | '<img src="/static/icons/reset.png" width="20">',
                      customIcons: []
                    }
                  }
                },
                title: {
                  text: "Orders per hour",
                  align: 'center',
                  margin: 10,
                  offsetX: 0,
                  offsetY: 0,
                  floating: false,
                  style: {
                    fontSize:  '14px',
                    fontWeight:  'bold',
                    fontFamily:  undefined,
                    color:  '#263238'
                  }
                },
                colors: [alpha(theme.palette.primary.main, 0.25), theme.palette.primary.main],
                dataLabels: {
                  enabled: false
                },
                fill: {
                  opacity: 1,
                  type: 'solid'
                },
                grid: {
                  borderColor: theme.palette.divider,
                  strokeDashArray: 2,
                  xaxis: {
                    lines: {
                      show: false
                    }
                  },
                  yaxis: {
                    lines: {
                      show: true
                    }
                  }
                },
                legend: {
                  show: false
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: '70%',
                    barHeight: '70%',
                    distributed: false,
                    colors: {
                      backgroundBarColors: Array
                    }
                  }
                },
                stroke: {
                  colors: [alpha(theme.palette.primary.main, 0.25), theme.palette.primary.main],
                  show: true,
                  curve: 'smooth', // 'smooth', 'straight', 'stepline'
                  lineCap: 'square', // 'butt', 'square', 'round'
                  width: 2
                },
                theme: {
                  mode: theme.palette.mode
                },
                xaxis: {
                    tickPlacement: 'on',
                    axisBorder: {
                      color: theme.palette.divider,
                      show: true
                    },
                    axisTicks: {
                      color: theme.palette.divider,
                      show: true
                    },
                    labels: {
                      offsetY: 5,
                      style: {
                        colors: theme.palette.text.secondary
                      }
                    }
                },
                markers: {
                  size: [0, 0, 3, 3]
                },              
                yaxis: [
                  {
                    seriesName: this.state.date + ' Value',
                    show: true,
                    min: 0,
                    max: maxM,
                    labels: {
                      formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
                      offsetX: -10,
                    }
                  },
                  {
                    seriesName: today + ' Value',
                    show: false,
                    min: 0,
                    max: maxM,
                    labels: {
                    formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
                    offsetX: -10,
                    style: {
                      colors: theme.palette.text.secondary
                    }
                  }
                  },
                  {
                    seriesName: this.state.date + ' Orders',
                    opposite: true,
                    show: true,
                    min: 0,
                    max: maxM_trans,
                    labels: {
                    formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
                    offsetX: -10,
                    style: {
                      colors: theme.palette.text.secondary
                    }
                  }
                  },
                  {
                    seriesName: today + ' Orders',
                    opposite: true,
                    show: false,
                    min: 0,
                    max: maxM_trans,
                    labels: {
                    formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
                    offsetX: -10,
                    style: {
                      colors: theme.palette.text.secondary
                    }
                  }
                  },
                ]
              }}
              series={[
                {	
                  name: this.state.date + ' Value',
                  data: orders_day_value,
                  type: 'bar'
                },
                {	
                  name: today + ' Value',
                  data: orders_today_value,
                  type: 'bar'
                },
                {	
                  name: this.state.date + ' Orders',
                  data: orders_day_trans,
                  type: 'line'
                },
                {	
                  name: today + ' Orders',
                  data: orders_today_trans,
                  type: 'line',
                }
              ]}
              width="100%"
              />
            )}          
        </Box>  
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            color="inherit"
            endIcon={(
              <SvgIcon fontSize="small">
                <ArrowForwardIcon />
              </SvgIcon>
            )}
            size="small"
          >
            Overview
          </Button>
        </CardActions>
      </Card>
    );

  }
}

export default OverviewOrders;