import React from 'react';
import { Avatar, Box, Card, CardActions, CardContent, CardHeader, Divider, SvgIcon, Typography, Table, TableBody, TableCell, TableHead, TableRow, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import noImage from '../../images/noImage.png';

import Client from '../Client';
import Config from '../ClientConfig';

import { SeverityPill } from './severity-pill';

const statusMap = {
  pending: 'warning',
  delivered: 'success',
  refunded: 'error'
};

class OverviewLatestCustomers extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      clients: [],
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);

  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    let queryData = { "type": 0 }
	let data = this.state.client.getData ( Config.API_URL_REPORTS_CLIENTS, queryData );
    if ( data ) {
      this.setState ( { clients: data.clients });
    }
    this.setState ( { filterData: queryData } );
  }

  doSync ( ) {
    this.getData ( );
  }

  render() {
		let clientImage = noImage;
		let clientsLatest = [];

    // console.log(this.state.clients);
		if (this.state.clients){
			Object.keys(this.state.clients).forEach(key => {
				clientImage = noImage;
				let fData = this.state.clients [ key ];
				if (fData.user.person.image == null) {
					clientImage = noImage;;
				}
				else {
					clientImage = fData.user.person.image;
				}
				let c = {
					id: fData.iref,
					image: clientImage,
					name: fData.user.first_name + " " + fData.user.last_name,
					createdAt: fData.createdOn,
					status: fData.user.user_status,
					credit: fData.credit,
					birthday: fData.user.person.birthday
				}	
				clientsLatest.push ( c );   
			});
		}

    return (
    <Card sx={{ borderRadius:"16px", height: '100%' }}>
      <CardHeader title="Latest Customers" />
      {/* <Scrollbar sx={{ flexGrow: 1 }}> */}
        <Box>
          <Table style={{ maxWidth: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  Client
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell sortDirection="desc">
                  Date Created
                </TableCell>
                <TableCell>
                  Credit
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clientsLatest.map((client) => {
                const createdAt = client.createdAt;

                return (
                  <TableRow
                    hover
                    key={client.id}
                  >
                    <TableCell>
                      {
                        client.image
                          ? (
                            <Box
                              component="img"
                              src={client.image}
                              sx={{
                                borderRadius: 1,
                                height: 32,
                                width: 32
                              }}
                            />
                          )
                          : (
                            <Box
                              sx={{
                                borderRadius: 1,
                                backgroundColor: 'neutral.200',
                                height: 32,
                                width: 32
                              }}
                            />
                          )
                      }
                    </TableCell>
                    <TableCell>
                      {client.name}
                    </TableCell>
                    <TableCell>
                      {createdAt}
                    </TableCell>
                    <TableCell>
                      <SeverityPill color={statusMap[client.credit]}>
                        {client.credit}
                      </SeverityPill>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      {/* </Scrollbar> */}
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          color="inherit"
          endIcon={(
            <SvgIcon fontSize="small">
              <ArrowForwardIcon />
            </SvgIcon>
          )}
          size="small"
          variant="text"
        >
          View all
        </Button>
      </CardActions>
    </Card>
    );

  }
}

export default OverviewLatestCustomers;


 {/* <Button
                color="inherit"
                size="small"
                startIcon={(
                <SvgIcon fontSize="small">
                    <AutorenewIcon />
                </SvgIcon>
                )}
                onClick={() => this.doSync()}
            /> */}