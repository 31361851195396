import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider, touchRippleClasses } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser } from "react-icons/fa";
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import swal from 'sweetalert';
import noImage from './../images/noImage.png';
import idle from './../images/icons/idle_on.png';
import registered from './../images/icons/registered_on.png';
import active from './../images/icons/active_on.png';
import discontinued from './../images/icons/discontinued_on.png';

import level_employee from './../images/icons/employee.png';
import level_coordinator from './../images/icons/coordinator.png';
import level_owner from './../images/icons/owner.png';

import employee_month from './../images/icons/employee_month.png';
import employee_month2 from './../images/icons/employee_month2.png';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PasswordIcon from '@mui/icons-material/Password';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PinIcon from '@mui/icons-material/Pin';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import BackOfficeConfig from './../Config';
import Pin from '@mui/icons-material/Pin';

import Client from './Client';
import Config from './ClientConfig';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';

import TokenIcon from '@mui/icons-material/Token';
import UpdateIcon from '@mui/icons-material/Update';
import DropDown from './DropDown';


let theme = createTheme();

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#273442",
    color: theme.palette.common.white,
    paddingLeft: "4px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingLeft: "4px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const LocationSelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: "300px",
    },
  },
};

class LocationRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      client: new Client ( "none" ),     
      edit: false,
      row: {},
      somethingHasChanged: false
      //addNewProduct: false,
    };

    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.changeType = this.changeType.bind(this);
    this.pushData = this.pushData.bind(this);
  }

  componentDidMount ( ) {
    if ( this.props ) {
      if ( this.props.dataRow ) {
        this.setState ( { row: this.props.dataRow } );
      }
    }
  }

  handleEditClick ( ) {
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
  }

  changeType ( e ) {
    let uRow = this.state.row;
    uRow.level = parseInt ( e );
    this.setState ( { row: uRow } );
    this.setState ( { somethingHasChanged: true } );
  }

  pushData ( e ) {
    if ( this.state.edit && this.state.somethingHasChanged ) {      
      let row = this.state.row;
      let rdata = this.state.client.pushData ( Config.API_URL_LOCATIONEMPLOYEES_UPDATE_LEVEL, row );
      if ( rdata ) {
        if ( rdata.status == "OK" ) {
          let uRow = this.state.row;
          uRow.iref = rdata.iref;
          this.setState ( { row: uRow } );
          this.handleEditClick ( );
          swal ( "Success", "Successfully added/modified employee ! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            //this.props.employeeUpdated ( );
            this.setState ( { somethingHasChanged: false } );            
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Error", "Add/Update employee failed !", "error" );
        }
      }
    }
  }

  handleSave ( ) {
    this.pushData ( );
  }


  render() {
      let name = "";
      let status = "";
      let locationType = "";
      let relation = "";
      let add_street = "";
      let add_number = "";
      let add_floor = "";
      let add_city = "";
      let add_county = "";
      let add_country = "";
      let locationImage = noImage;
      let companyName = "";
      let companyUID = "";
      let level = 0;
      let levelText = "Employee";

      let row = [];
      if ( this.props.dataRow !== null && this.props.dataRow != undefined ) {
        row = this.props.dataRow;
      }

      if ( this.state.row ) {
        let row = this.state.row;

        if ( row.level !== undefined ) {
          level = row.level;
          switch ( row.level ) {
            case 0:        
              levelText = "Employee";
              break;
            case 1:
              levelText = "Coordinator";
              break;
            case 2:        
              levelText = "Owner";
              break;
          }
        }

        if ( row.location ) {
          name = row.location.name;
          status = row.location.status;
          locationType = row.location.locationType;
          relation = row.location.relation;
          
          if ( row.location.address ) {
            add_street = row.location.address.street;
            add_number = row.location.address.number;
            add_floor = row.location.address.floor;
            if ( row.location.address.city ) {
              add_city = row.location.address.city.name;
              if ( row.location.address.city.county ) {
                add_county = row.location.address.city.county.name;
                if ( row.location.address.city.county.country ) add_country = row.location.address.city.county.country.name;
              }
            }
          }
  
          let locationImagesObj = row.location.locationImage_location;
          let locationImages = Object.entries ( locationImagesObj );
          let fImage = false;
          locationImages.map ( (image) => {
            if ( fImage === false ) {
              locationImage = image[1].image;
              fImage = true;
            } else {
              if ( image[1].isMain === true ) locationImage = image[1].image;
            }          
          });
  
          if ( row.location.company ) {
            companyName = row.location.company.name;
            companyUID = row.location.company.cuid;
          }
  
        }
      }

      let showSave = false;
      if ( this.state.edit && this.state.somethingHasChanged )
        showSave = true;
    
    return (
      <StyledTableRow key={row.iref} sx={{padding: "1px"}}>

        <StyledTableCell align="left" sx={{padding: "1px"}}>        
          <Box
            component="img"
            sx={{
              height: 48,
              width: 48,
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 },
              border: '1px dashed grey'
            }}
            alt={name}
            src={locationImage}
          />
        </StyledTableCell>

        <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>
          {name}
        </StyledTableCell>

        <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>         
          {companyName} ({companyUID})
        </StyledTableCell>

        <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>
          {add_street}, n. {add_number}, et. {add_floor}, {add_city}, {add_county}, {add_country}
        </StyledTableCell>

        <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>
          {this.state.edit ?
            <DropDown
              name="Employee Type"
              value={level}
              doptions={BackOfficeConfig.EMPLOYEE_TYPES}
              hasIcons={false} 
              minWidth={120}
              onChangeFunction={this.changeType}
              size="small"
            />
            : levelText
          }
          
        </StyledTableCell>

        <StyledTableCell align="right" sx={{padding: "1px"}}>
          <IconButton aria-label="settings" onClick={this.handleEditClick}>
            <EditIcon sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }} />
          </IconButton>
          {this.state.edit && showSave ?
            <IconButton aria-label="settings" onClick={this.handleSave}>
              <SaveIcon sx={{ color: "#4287f5" }} />
            </IconButton>
          : ""}
        </StyledTableCell>

      </StyledTableRow>
    );
  }
}

class ScheduleRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {      
      edit: false,
      //addNewProduct: false,
    };

    this.handleEditClick = this.handleEditClick.bind(this);
  }

  handleEditClick ( ) {
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
  }


  render() {
      let row = [];
      if ( this.props.dataRow !== null && this.props.dataRow != undefined ) {
        row = this.props.dataRow;
      }

      let day = "Undefined";
      let from = "Undefined";
      let to = "Undefined";      
      if ( row ) {
        day = BackOfficeConfig.WEEKDAYS_RO [ row.day ];
        from = row.interval_start;
        to = row.interval_end;        
      }      
   
    return (
      <StyledTableRow key={row.iref} sx={{padding: "1px"}}>

        <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>
          {day}
        </StyledTableCell>

        <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>         
          {from}
        </StyledTableCell>

        <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>
          {to}
        </StyledTableCell>

        <StyledTableCell align="right" sx={{padding: "1px"}}>
          <IconButton aria-label="settings" onClick={this.handleEditClick}>
            <EditIcon sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }} />
          </IconButton>
          {this.state.edit ?
            <IconButton aria-label="settings" onClick={this.handleEditClick}>
              <SaveIcon sx={{ color: "#4287f5" }} />
            </IconButton>
          : ""}
        </StyledTableCell>

      </StyledTableRow>
    );
  }
}


class Employee extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      expanded: false,
      edit: false,
      somethingHasChanged: false,
      saveButtonVisible: false,
      //combo_mainImage: null,
      employee: {},
      personsSearch: [],
      usernameValid: false,
      passwordValid: false,
      cardValid: false,
      pinValid: false,
      locations: [],
      locationNames: [],
      locationsIref: []
    };

    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.getElementByName = this.getElementByName.bind(this);
    this.personChange = this.personChange.bind(this);
    this.personSearch = this.personSearch.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.pushData = this.pushData.bind(this);
    this.changeLocation = this.changeLocation.bind(this);    
    this.getLocations = this.getLocations.bind(this);    
  }


  componentDidMount() {
    if ( Object.entries(this.props.employee).length === 0 ) {
      this.handleEditClick ( );
    } else {
      if ( this.props.employee !== null ) {
        let uEmployee = this.props.employee;        
        this.setState ( { employee: uEmployee } );

        if ( uEmployee.username.length > 3 )
          this.setState ( { usernameValid: true } );
        if ( uEmployee.password.length >= 8 )
          this.setState ( { passwordValid: true } );
        if ( uEmployee.card.length >= 8 )
          this.setState ( { cardValid: true } );
        if ( uEmployee.pin.length >= 4 )
          this.setState ( { pinValid: true } );


        if ( this.props.employee.employeeLocation_employee ) {
          let eloc = this.props.employee.employeeLocation_employee;
          if ( eloc.length > 0 ) {
            let els = [];
            eloc.map((el) => {
              let newLocation = "[" + el.location.iref + "][" + el.location.address.city.county.name + ", " + el.location.address.city.name + "] " + el.location.name;
              els.push ( newLocation );
            });
            this.setState ( { locationNames: els } );
          }
        }
      }
    }
  }

  componentDidUpdate ( oldProps ) {
    if ( this.props != oldProps ) {
      if ( this.props.employee !== null ) {
        let uEmployee = this.props.employee;        
        this.setState ( { employee: uEmployee } );
      }
    }
  }

  handleExpandClick ( ) {
    this.setState({ expanded: !this.state.expanded });
  }

  getLocations ( ) {
    let queryData = {
      "orderBy_county": true,
      "orderBy_name": true
    }
    let data = this.state.client.getData ( Config.API_URL_LOCATIONS, queryData );
    if ( data ) {
      if ( data.locations ) {
        let locations = [];
        data.locations.map((location) => {
          let newLocation = "[" + location.iref + "][" + location.address.city.county.name + ", " + location.address.city.name + "] " + location.name;
          locations.push ( newLocation );
        });
        this.setState ( { locations: locations } );
      }
    }
    this.setState ( { employees: data.employees });
  }

  handleEditClick ( ) {
    //let editMode = this.state.edit;
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
    if ( this.state.expanded === false && cEdit === true )
      this.state.expanded = true;
    if ( this.state.expanded === true && cEdit === false )
      this.state.expanded = false;
    this.setState({ saveButtonVisible: cEdit });
    if ( cEdit ) this.getLocations ( );
  }

  getElementByName ( elements, name ) {    
    let e = {
      "iref" : null,
      "name" : "X",
      "image" : "X",
      "image_off" : "X"
    };
    if ( elements !== null && elements !== undefined ) {
      for ( var i = 0; i < elements.length; i++ ) {
        let el = elements [ i ];
        if ( el.length === 2 ) {
          if ( el [ 1 ].name === name ) e = el [ 1 ];
        } else {
          if ( el [ 0 ].name === name ) e = el [ 0 ];
        }        
      }
    }
    return e;    
  }

  personSearch ( e ) {
    //console.log ( e.target.value );
    if ( e.target.value.length > 3 ) {
      let stringLength = e.target.value.length;
      let limit = (stringLength - 2) * 20;
      let queryData = {
        "nameLike" : e.target.value,
        "notLinked_employee": true,
        "notLinked_user": true,
        "limit": limit
      };
      let data = this.state.client.filterPersons ( queryData );
      let newpersons = [];
      let persons = [];
      Object.keys(data.persons).forEach(key => {
        let fData = data.persons [ key ];
        let lineText = fData.iref + " - " + fData.firstName + " " + fData.lastName + " (" + fData.email + ", " + fData.phone + ")";
        let newObj = { key: fData.iref, label: lineText };
        newpersons.push ( newObj );
        persons.push ( fData );
        //counties [ fData.iref ] = fData.name;      
      });

      this.setState ( { personsSearch: newpersons });
    }
  }

  personChange ( e ) {
    let text = e.target.textContent;
    let repIref = text.substring ( 0, text.indexOf(" - ") );
    let queryData = { };
    let data = this.state.client.getData ( Config.API_URL_PERSON + repIref, queryData );
    let uEmployee = this.state.employee;
    uEmployee.person = data;
    this.setState ( { employee: uEmployee } );    
    this.setState ( { somethingHasChanged: true } );
  }

  changeValue ( e ) {
    let id = null;
    if ( e.target.id !== null ) id = e.target.id;
    let value = null;
    if  ( e.target.value !== null ) value = e.target.value;
    if ( id !== null) {
      let uEmployee = this.state.employee;
      switch ( id ) {
        case "username":
          uEmployee.username = value;
          if ( value.length > 3 ) this.setState ( { usernameValid: true } );
          else this.setState ( { usernameValid: false } );
          break;
        case "password":
          uEmployee.password = value;
          if ( value.length >= 8 || value.length == 0 ) this.setState ( { passwordValid: true } );
          else this.setState ( { passwordValid: false } );
          break;
        case "card":
          uEmployee.card = value;
          if ( value.length >= 8 ) this.setState ( { cardValid: true } );
          else this.setState ( { cardValid: false } );
          break;
        case "pin":
          uEmployee.pin = value;
          if ( value.length >= 4  || value.length == 0 ) this.setState ( { pinValid: true } );
          else this.setState ( { pinValid: false } );
          break;
      }
      this.setState ( { employee: uEmployee } );
      this.setState ( { somethingHasChanged: true } );
    }

  }

  pushData ( e ) {
    if ( this.state.edit && this.state.somethingHasChanged && this.state.usernameValid && this.state.passwordValid && this.state.cardValid && this.state.pinValid ) {
      let employee = this.state.employee;
      employee.employeeLocation_employee = { };
      if ( employee.person )
        if ( employee.person.address )
          employee.person.address = { }

      if ( employee.locations ) {
        for ( var l of employee.locations ) {
          if ( l.location ) {
            if ( l.location.address ) l.location.address = {}
            if ( l.location.company ) l.location.company = {}
          }
        }
      }

      let rdata = this.state.client.pushData ( Config.API_URL_EMPLOYEES_ADD, employee );
      if ( rdata ) {
        if ( rdata.status == "OK" ) {
          let uEmployee = this.state.employee;
          uEmployee.iref = rdata.iref;
          this.setState ( { employee: uEmployee } );
          this.handleEditClick ( );
          swal ( "Success", "Successfully added/modified employee ! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            this.props.employeeUpdated ( );
            this.setState ( { somethingHasChanged: false } );            
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Error", "Add/Update employee failed !", "error" );
        }
      }
    }
  }

  changeLocation ( e ) {
    let uLocationNames = this.state.locationNames;
    let value = e.target.value;
    let newValue = typeof value === 'string' ? value.split(',') : value;
    

    let locationsIrefs = [];
    newValue.map((v) => {
      let c1 = v.indexOf ( "[" ) + 1;
      let c2 = v.indexOf ( "]" );
      let iref = v.substring ( c1, c2 );
      locationsIrefs.push ( iref );
    });

    let uEmployee = this.state.employee;
    uEmployee.locations = locationsIrefs;
    this.setState ( { locationNames: newValue } );
    this.setState ( { employee: uEmployee } );
    this.setState ( { somethingHasChanged: true } );
  }

  render() {

    let iref = "";
    let employeeImage = noImage;
    let level = 9;
    let username = "";
    let password = "********";
    let card = "";
    let pin = "********";
    let tokenValid = "";
    let username_pass = "";
    let card_pin = "";

    let firstName = "";
    let lastName = "";
    let phone = "";
    let email = "";
    let birthday = "";
    let updateKey = "";

    let add_name = "";
    let add_postalCode = "";
    let add_street = "";
    let add_number = "";
    let add_entrance = "";
    let add_floor = "";
    let add_complement = "";
    let add_city = "";
    let add_county = "";
    let add_country = "";
    let modifiedOn = "";
    let allData ="";

    if ( this.state.employee ) {
      let employee = this.state.employee;
      if ( employee.iref ) iref = employee.iref;
      if ( employee.level != undefined ) level = parseInt ( employee.level );
      if ( employee.username ) username = employee.username;
      if ( this.state.edit && employee.password ) {
        if ( employee.password.startsWith ( "pbkdf2_sha256" ) )
          password = "********";
        else
          password = employee.password;
      }
      if ( employee.card ) card = employee.card;
      if ( this.state.edit && employee.pin ) {
        if ( employee.pin.startsWith ( "pbkdf2_sha256" ) )
          pin = "********";
        else
          pin = employee.pin;
      }
      if ( employee.tokenValid != undefined ) tokenValid = employee.tokenValid;
      if ( employee.modifiedOn ) modifiedOn = employee.modifiedOn;
      //if ( employee.image ) employeeImage = employee.image;

      username_pass = "Username :" + username + ", Password:" + password;
      card_pin = "Card: " + card + ", PIN: " + pin;

      if ( employee.person ) {
        firstName = employee.person.firstName;
        lastName = employee.person.lastName;
        phone = employee.person.phone;
        email = employee.person.email;
        birthday = employee.person.birthday;
        updateKey = employee.person.updateKey;

        if ( employee.person.image ) employeeImage = employee.person.image;

        if ( employee.person.address ) {
          let add = employee.person.address;  
          add_name = add.name;
          add_postalCode = add.postalCode;
          add_street = add.street;
          add_number = add.number;
          add_entrance = add.entrance;
          add_floor = add.floor;
          add_complement = add.complement;
    
          if ( add.city ) {
            add_city = add.city.name;
            if ( add.city.county ) {
              add_county = add.city.county.name;
              if ( add.city.county.country ) add_country = add.city.county.country.name;
            }
          }        
        }
      }

      if ( employee.locations ) {
        allData = employee.locations.map((row) => {
          return <LocationRow key = {row.iref} dataRow = {row}/>
        });
      }
    }

    let employeeName = firstName + " " + lastName;
    let employeeContact = "";
    if ( phone !== "null" && phone !== null ) employeeContact = phone + ", " + email;
    else employeeContact = email;

    let levelIcon = noImage;
    let levelText = "Undefined";
    switch ( level ) {
      case 0: levelIcon = level_employee; levelText = "Employee"; break;
      case 1: levelIcon = level_coordinator; levelText = "Coordinator"; break;
      case 2: levelIcon = level_owner; levelText = "Owner"; break;
    }

    let location_status_alt = "";
    let location_status_image = noImage;

    let personsSearch = [ ];
    if ( this.state.personsSearch ) personsSearch = this.state.personsSearch;

    let hasPerson = false;    
    if ( this.state.employee.person && 'iref' in this.state.employee.person ) hasPerson = true;

    let saveButtonVisible = false;
    if ( this.state.edit && this.state.somethingHasChanged && this.state.usernameValid &&
        this.state.passwordValid && this.state.cardValid && this.state.pinValid )
        saveButtonVisible = true;

    let locations = [ ];
    if ( this.state.locations ) locations = this.state.locations;

    let locationNames = [];
    if ( this.state.locationNames.length > 0 ) locationNames = this.state.locationNames;

    let userMargin = 0;
    let cardMargin = 0;
    if ( this.state.edit ) {
      userMargin = 2;
      cardMargin = 3;
    }
    

    return (
      /* , display: 'flex' */
    <Card sx={{ borderRadius: '16px', m: 1 }}>

      <Box sx={{ display: 'block', flexDirection: 'row', width: "100%" }}>
        <CardHeader
          avatar={
            <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                <Avatar alt={levelText} src={levelIcon} title={levelText}>
                </Avatar>  
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
              <Typography variant="subtitle1" color="text.secondary">
                {iref}
              </Typography>
              </Box>
            </Box>
                    
          }        
          action={            
            <IconButton aria-label="settings" onClick={this.handleEditClick}>
              <EditIcon sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}/>
            </IconButton>
          }
          title={employeeName}
          subheader={employeeContact}
          titleTypographyProps={{variant:'h5' }}
        />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>

        <Box sx={{ display: 'block', flexDirection: 'column', width: '20%'}}>
          <CardMedia
            component="img"
            height="120"
            sx={{ width: 128, marginLeft: 1 }}
            image={employeeImage}
            alt={employeeName}
          />
        </Box>
        
        <Box sx={{ flexDirection: 'column', display: "flex", width: '30%', justifyContent:"left" }}>

          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
            <CardContent sx={{textAlign: "start", justifyContent:"left"}}>
              {this.state.edit ? 
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  name="Person"
                  options={personsSearch}
                  sx={{ width: 280 }}
                  onChange={this.personChange}
                  renderInput = {
                    (params) =>
                      <TextField 
                        {...params}
                        label="Person (type keywords)"
                        onChange={this.personSearch}
                      />}
                />
                :
                <Typography variant="body2" color="text.secondary">                
                  {birthday}
                </Typography>
              }
            </CardContent>
          </Box>

          {!this.state.edit ?
            <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
              {/* <CardActions disableSpacing sx={{display: "flex", justifyContent: "left" }}>
                <IconButton aria-label="Employee of the month" title="Employee of the month">       
                  <Avatar alt="Employee of the month" src={employee_month} sx={{ width: 48, height: 48 }}/>
                </IconButton>
                <IconButton aria-label="Employee of the month" title="Employee of the month">       
                  <Avatar alt="Employee of the month" src={employee_month2} sx={{ width: 48, height: 48 }}/>
                </IconButton>
              </CardActions> */}
            </Box>
            : ""
          }
        </Box>

        
        <Box sx={{ flexDirection: 'column', justifyContent:"right", width: '50%' }}>
          {!this.state.edit ?
            <CardActions disableSpacing sx={{display: "block", textAlign: "end"}}>
              <b>{add_name}</b><br/>
              {add_street}, n. {add_number}, Et. {add_floor}, {add_complement}<br/>
              {add_city}, {add_county}, {add_country}
            </CardActions>
            : ""
          }
          <CardActions disableSpacing sx={{display: "block", textAlign: "end"}}>
            <AccountBoxIcon fontSize="small" color="primary" sx={{ marginTop: userMargin }}/>&nbsp;
            {this.state.edit ?
              <TextField
                id="username"
                label="Username"
                value={username || ''}
                variant="outlined"
                onChange={this.changeValue}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"32"}}
                error={this.state.usernameValid === false}
                sx={{ width: 140 }}
              />
              :
              username
            }            
            &nbsp;
            <PasswordIcon fontSize="small" color="error" sx={{ marginTop: userMargin }}/>&nbsp;
            {this.state.edit ? 
              <TextField
                id="password"
                label="Password"
                value={password || ''}
                variant="outlined"
                onChange={this.changeValue}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"32"}}
                error={this.state.passwordValid === false}
                sx={{ width: 140 }}
              />
              :
              password
            }
            <IconButton aria-label="Generate" onClick={() => {navigator.clipboard.writeText(username_pass)}} sx={{marginTop: 0, marginLeft: 0}}>
              <ContentCopyIcon fontSize="small" titleAccess="Copy to clipboard"/>
            </IconButton><br/>


            <CreditCardIcon fontSize="small" color="primary" sx={{ marginTop: cardMargin }}/>&nbsp;
            {this.state.edit ? 
              <TextField
                id="card"
                label="Card"
                value={card || ''}
                variant="outlined"
                onChange={this.changeValue}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"32"}}
                error={this.state.cardValid === false}
                sx={{ width: 140, marginTop: 1 }}
              />
              :
              card
            }
            &nbsp;
            <PinIcon fontSize="small" color="error" sx={{ marginTop: cardMargin }}/>&nbsp;
            {this.state.edit ? 
              <TextField
                id="pin"
                label="Pin"
                value={pin || ''}
                variant="outlined"
                onChange={this.changeValue}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"32"}}
                error={this.state.pinValid === false}
                sx={{ width: 140, marginTop: 1 }}
              />
              :
              pin
            }
            <IconButton aria-label="Generate" onClick={() => {navigator.clipboard.writeText(card_pin)}} sx={{marginTop: 0, marginLeft: 0}}>
              <ContentCopyIcon fontSize="small" titleAccess="Copy to clipboard"/>
            </IconButton>
          </CardActions>
          
        </Box>
      </Box>         
          
      <Box sx={{ flexDirection: 'row', width: '100%', display: 'flex' }}>
        <Box sx={{ flexDirection: 'column', justifyContent:"left", width: '100%' }}>
          <CardActions disableSpacing sx={{display: "flex", textAlign: "start", justifyContent:"left"}}>
            <UpdateIcon size="small" titleAccess="Modified"/>&nbsp;
            {modifiedOn},
            <TokenIcon size="small" titleAccess="Token Valid"/>&nbsp;
            {tokenValid}
          </CardActions>          
        </Box>          
        <Box sx={{ flexDirection: 'column', justifyContent:"right", width: '100%' }}>
          <CardActions disableSpacing sx={{display: "block", textAlign: "end" }}>                
           {/*  {username}, {card}    */}
          <ExpandMore
            expand={this.state.expanded}
            onClick={this.handleExpandClick}
            aria-expanded={this.state.expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
          </CardActions>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit sx={{width: "100% !important"}}>
        <CardContent>
          
          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "middle", justifyContent:"center", alignItems: "center", paddingTop: "8px"}}>
            {this.state.edit ?
              <FormControl sx={{ m: 1, width: "80%" }}>
                <InputLabel id="locationsSelect">Locations</InputLabel>
                <Select
                  labelId="locationsSelect"
                  id="locationsCheckBox"
                  multiple
                  value={locationNames}
                  onChange={this.changeLocation}
                  input={<OutlinedInput label="Locations" />}                  
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, width: "80%"}}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}                    
                    /* selected.join(', ')} */
                  sx={{ minWidth: "300px", maxWidth: "100%", width: "800px" }}
                  MenuProps={LocationSelectMenuProps}
                >
                  {locations.map((location) => (
                    <MenuItem key={location} value={location}>
                      <Checkbox checked={locationNames.indexOf(location) > -1} />
                      <ListItemText primary={location} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              :
              <Table sx={{ minWidth: 700, textAlign: "middle", justifyContent:"center" }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Image</StyledTableCell>
                    <StyledTableCell align="left">Name</StyledTableCell>
                    <StyledTableCell align="left">Company</StyledTableCell>            
                    <StyledTableCell align="left">Address</StyledTableCell>            
                    {/* <StyledTableCell align="left">City</StyledTableCell>
                    <StyledTableCell align="left">County</StyledTableCell>
                    <StyledTableCell align="left">Country</StyledTableCell>
                    <StyledTableCell align="left">Region</StyledTableCell> */}
                    <StyledTableCell align="left">Level</StyledTableCell>
                    <StyledTableCell align="right">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allData}
                </TableBody>
              </Table>
            }   
          </Box>



          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "end", justifyContent:"right", alignItems: "right", paddingTop: "8px" }}>
            {saveButtonVisible ?
              <Button
                variant="contained"
                size="small"
                sx={{ color: "#e3e3e5" }}
                startIcon={<SaveIcon />}
                onClick={this.pushData}
              >
                Save
              </Button>
              : ""
            }
          </Box>
          </CardContent>

        </Collapse>

      </Box>
    </Card>
   
    );

  }
}

export default Employee;
