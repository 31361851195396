import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser, FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
/* import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'; */
import Client from './Client';
import Allergen from './Allergen';
import Category from './Category';
import SubCategory from './SubCategory';
import ProductClass from './ProductClass';
import DropDown from './DropDown';
import Config from './ClientConfig';

//import './css/dashboard.css';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import noImage from './../images/icons/noImage_1.png';

import employee_off from './../images/icons/employee_off.png';
import employee from './../images/icons/employee.png';
import coordinator_off from './../images/icons/coordinator_off.png';
import coordinator from './../images/icons/coordinator.png';
import owner_off from './../images/icons/owner_off.png';
import owner from './../images/icons/owner.png';

let theme = createTheme();

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

class EmployeesFilters extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      productCategories: null,
      expanded: false,
      addNew: false,
      filters_reset: 1,
      locations: {},

      filtersCounter: 0,
      resetState: false,
      resetClasses: false,
      resetCategories: false,
      resetCategoriesList: { },
      resetSubCategoriesList: { },
      resetClassesList: { },
      resetSubCategories: false,
      filters: { },
      counties : {},
      countySelected: "",
      locationGroupSelect: 0,
      countrySelected: "country061",
      locationSelected: "",
      queryData: { },
      staticQueryData: { },
    };

    this.queryData = { };
    this.employeeLevel = { "employee": 0, "coordinator": 1, "owner": 2, "undefined": 9 };
    this.searchKeyword = React.createRef();
    this.categoriesStates = { };

    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.resetStatus = this.resetStatus.bind(this);
    this.removeQueryElement = this.removeQueryElement.bind(this);
    this.addQueryElement = this.addQueryElement.bind(this);
    this.toggleElement = this.toggleElement.bind(this);
    this.searchKey = this.searchKey.bind(this);
    
    this.resetFilters = this.resetFilters.bind(this);
    this.setFlagsFilters = this.setFlagsFilters.bind(this);
    this.setStatusFilters = this.setStatusFilters.bind(this);
    
    
    
    //this.getClasses = this.getClasses.bind(this);
    this.toggleAdd = this.toggleAdd.bind(this);

    this.addLocation = this.addLocation.bind(this);
    this.addCountry = this.addCountry.bind(this);
    this.addCounty = this.addCounty.bind(this);
    this.addLocationsGroup = this.addLocationsGroup.bind(this);

  }

  componentDidMount() {
    let queryData = {};
    let locations = this.state.client.getData ( Config.API_URL_LOCATIONS_SIMPLE, queryData );
    this.setState ( { locations: locations.locations });

    queryData = {
      country: this.state.countrySelected,
      orderBy_Name: true
    }
    let nQueryData = queryData;
    if ( this.props.queryData ) {
      nQueryData = Object.assign ( {}, queryData, this.props.queryData );
      this.setState ( { queryData: this.props.queryData } );
      this.setState ( { staticQueryData: this.props.queryData } );
    }
    this.queryData = nQueryData;
    
    //queryData [ "user_type_gte" ] = "15"
    let data = this.state.client.getData ( Config.API_URL_COUNTY, nQueryData );
    if ( data ) {
        this.setState ( { counties: data.counties });
    } else {
      this.setState ( { counties: {} });
    }
  }

  componentWillUnmount() {
    //this.searchKeyword = React.useRef(null);
  }

  componentDidUpdate ( prevProps ) {
    if ( this.props != prevProps ) {
      if ( this.props.addNew !== undefined )
        this.setState ( {addNew: this.props.addNew} );
    }
  }

  handleExpandClick ( ) {
    this.setState({ expanded: !this.state.expanded });
  }

  setStatusFilters ( value ) {
    this.setState ( {filters_level_employee: value} );
    this.setState ( {filters_level_coordinator: value} );
    this.setState ( {filters_level_owner: value} );
    this.setState ( {filters_level_discontinued: value} );
  }

  setFlagsFilters ( value ) {
    
    /* this.setState ( {filters_flags_isDeliverable: value} );
    this.setState ( {filters_flags_isDiscountable: value} );
    this.setState ( {filters_flags_isForSale: value} );
    this.setState ( {filters_flags_isNew: value} );
    this.setState ( {filters_flags_isSellable: value} ); */
  }

  toggleAdd ( ) {
    let addButton = this.state.addNew;
    this.setState ( {addNew: !addButton} );
    this.props.toggleAdd ( );
  }

  searchKey ( e ) {    
    let keyword = e.target.value;
    if ( keyword.length > 2 ) {
      this.addQueryElement ( "nameLike", keyword );
      this.props.applyFilters ( this.queryData );
    } else {
      if ( "nameLike" in this.queryData ) {
        delete this.queryData [ "nameLike" ];
        this.props.applyFilters ( this.queryData );
      }
    }
    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );
  }

  resetStatus ( element ) {
    /* if ( "status" in this.queryData )        
      delete this.queryData.status; */
    if ( element !== "employee" ) this.setState ( { filters_level_employee: 0 } );
    if ( element !== "coordinator" ) this.setState ( { filters_level_coordinator: 0 } );
    if ( element !== "owner" ) this.setState ( { filters_level_owner: 0 } );
    if ( element !== "discontinued" ) this.setState ( { filters_level_discontinued: 0 } );
  }

  removeQueryElement ( element ) {
    if ( element ) {
      if ( element in this.queryData ) {
        if ( this.queryData [ element ] !== null && this.queryData [ element ] !== undefined ) {
          delete this.queryData [ element ];
        }
      }
    }
  }

  addQueryElement ( element, value ) {
    //console.log ( "addQueryElement: " + element + " -> " + value );
    if ( element ) {
      if ( element in this.queryData ) {
        if ( this.queryData [ element ] !== null && this.queryData [ element ] !== undefined && value !== null && value !== undefined ) {
          if ( this.queryData [ element ] != value ) this.queryData [ element ] = value;
          else delete this.queryData [ element ];
        } else {
          delete this.queryData [ element ];
        }
      } else {
        if ( value !== null && value !== undefined )
          this.queryData [ element ] = value;
        else
          this.queryData [ element ] = 1;
      }
    }

    /* console.log ( "addQueryElement ---> " );
    console.log ( this.queryData ); */
  }

  toggleElement ( prefix, element, value ) {
    let stateName = "filters_" + prefix + "_" + element;
    let status = this.state [ stateName ];
    this.setState ( { [stateName]: !status } );
    if ( prefix == "level" ) element = "level";
    if ( value !== null && value !== undefined ) {
      this.addQueryElement ( element, value );
    } else {
      this.addQueryElement ( element );
    }
  }


  toggleFilter ( filterType, filterName ) {
    switch ( filterType ) {
      case "level":
        this.resetStatus ( filterName );
        let level = this.employeeLevel [ filterName ];
        this.toggleElement ( "level", filterName, level );
        break;
    }

    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );

    //if ( Object.keys(this.queryData).length > 0 ) {
    this.props.applyFilters ( this.queryData );
    this.setState ( {resetState: false});
/*     this.setState ( {resetClasses: false});
    this.setState ( {resetCategories: false});
    this.setState ( {resetSubCategories: false}); */
    //}
  }

  addLocation ( key ) {
    if ( key === "0" ) this.removeQueryElement ( "country" );
    else this.addQueryElement ( "location", key );
    this.setState ( {locationSelected: key} );
    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );    
    this.props.applyFilters ( this.queryData );
  }

  addCountry ( key ) {
    if ( key === "0" ) this.removeQueryElement ( "country" );
    else this.addQueryElement ( "country", key );
    this.removeQueryElement ( "county" );
    this.setState ( { countySelected: "" } );
    this.setState ( {countrySelected: key} );
    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );    
    this.props.applyFilters ( this.queryData );

    let queryData = {
      country: key,
      orderBy_Name: true
    }
    //queryData [ "user_type_gte" ] = "15"
    let data = this.state.client.getData ( Config.API_URL_COUNTY, queryData );
    if ( data ) {
        this.setState ( { counties: data.counties });
    } else {
      this.setState ( { counties: {} });
    }
  }

  addCounty ( key ) {
    if ( key === "0" ) this.removeQueryElement ( "county" );
    else this.addQueryElement ( "county", key );
    this.setState ( { countySelected: key } );
    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );    
    this.props.applyFilters ( this.queryData );
  }

  addLocationsGroup ( key ) {
    if ( key === "0" ) this.removeQueryElement ( "locationGroup" );
    else this.addQueryElement ( "locationGroup", key );
    let filtersCounter = Object.keys(this.queryData).length;
    this.setState ( {filtersCounter: filtersCounter} );    
    this.props.applyFilters ( this.queryData );
  }

  resetFilters ( ) {
    if ( this.queryData ) this.queryData = { };
    this.setStatusFilters ( 0 );
    this.setFlagsFilters ( 0 );
    this.removeQueryElement ( "location" );
    this.removeQueryElement ( "country" );
    //this.setState ( { locationGroupSelect: 0 } );
    this.removeQueryElement ( "county" );
    this.addCountry ( "country061" );    
    this.removeQueryElement ( "locationGroup" );
    this.addLocation ( "0" );
    this.setState ( { locationGroupSelect: 0 } );
    this.setState ( {filters_reset: 1} );
    this.searchKeyword.current.value = "";
    this.setState ( {filtersCounter: 0} );
    //this.props.applyFilters ( this.queryData );
    this.setState ( {resetState: true});
/*     this.setState ( {resetClasses: true});
    this.setState ( {resetCategories: true});
    this.setState ( {resetSubCategories: true}); */
    //this.resetAllClassesButThis ( "" );
    //this.resetAllSubCategoriesButThis ( "" );
    //this.resetAllCategoriesButThis ( "" );

    this.setState ( { countrySelected: "country061" } );
    let queryData = {
      country: "country061",
      orderBy_Name: true
    };
    let nQueryData = queryData;
    if ( this.state.staticQueryData ) nQueryData = Object.assign ( {}, queryData, this.state.staticQueryData );
    this.queryData = nQueryData;
    this.props.applyFilters ( this.queryData );
  }

  

  

 /*  getClasses ( ) {
    let classesRender = "";
    if ( this.props.classesList ) {
      let classesArr = Object.entries ( this.props.classesList );      
      classesRender = classesArr.map ( (productClass) => {
        let img_on = "";
        let img_off = "";
        let name = "";
        let img = "";
        let iref = "";
        if ( productClass.length === 2 ) {          
          iref = productClass [ 1 ].iref;
          name = productClass [ 1 ].name;
          img_on = productClass [ 1 ]. image;
          img_off = productClass [ 1 ]. image_off;
        } else {
          iref = productClass [ 0 ].iref;
          name = productClass [ 0 ].name;
          img_on = productClass [ 0 ]. image;
          img_off = productClass [ 0 ]. image_off;
        }
        img = img_off;
        
      return <ProductClass
        iref = {iref}
        name = {name}        
        image_on = {img_on}
        image_off = {img_off}
        onClickFunction = {this.toggleFilter}
        doReset={this.state.resetClassesList [ iref ]}
        />
      });
    }
    return classesRender;
  } */

  render() { 

    /* let classesRender = null;
    classesRender = this.getClasses ( );
    if ( this.state.filters_reset === 1 ) {
      //classesRender = this.getclasses ( );
      //if ( classesRender != null ) this.setState ( {filters_reset: 0} );
    } */

    let locations = {"0": "All"};
    if ( this.state.locations ) {
      let locs = Object.entries ( this.state.locations );
      locs.map ( (loc) => {
        locations [ loc[1].iref ] = loc[1].name;
        //console.log ( loc );
      });
      //Object.assign ( locations, locations, this.state.locations );
    }

    //let countriesValue = "";
    let countries = {};
    if ( this.props.countries ) countries = this.props.countries;
    //if ( Object.keys(countries).length === 1 ) countriesValue = Object.keys(countries)[0];

    let counties = {};
    let countiesObj = {};
    if ( Object.entries(this.state.counties).length !== 0 ) {
      countiesObj = this.state.counties;
      Object.keys(countiesObj).forEach(key => {
        let fData = countiesObj [ key ];
        counties [ fData.iref ] = fData.name;      
      });
    }

    let locationsGroups = {"0": "All"};
    if ( this.props.locationsGroups ) Object.assign ( locationsGroups, locationsGroups, this.props.locationsGroups );

    let countrySelect = "";
    if ( this.state.countrySelected ) countrySelect = this.state.countrySelected;
    let countySelect = "";
    if ( this.state.countySelected ) countySelect = this.state.countySelected;

    let locationSelect = "";
    if ( this.state.locationSelected ) locationSelect = this.state.locationSelected;

    return (
      <Card sx={{ borderRadius: '16px', m: 1, backgroundColor: "#214a80" }}>
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

        <CardActions disableSpacing sx={{display: "block", textAlign: "flex-end"}}>          
          <IconButton aria-label="Add Location" title="Add Location" onClick={() => this.toggleAdd()}>       
            <Avatar alt="Add Location" sx={{ width: 24, height: 24, color: "#273442" }}>
            {this.state.addNew ? <FaMinusCircle/> : <FaPlusCircle/>}
              </Avatar>
          </IconButton>
        </CardActions>


        <CardActions disableSpacing sx={{display: "flex", textAlign: "start"}}>
          <IconButton aria-label="Employee" title="Employee" onClick={() => this.toggleFilter("level", "employee")}>
            <Avatar alt="Employee" src={this.state.filters_level_employee ? employee : employee_off} sx={{ width: 24, height: 24 }}/>
          </IconButton>
          <IconButton aria-label="Coordinator" title="Coordinator" onClick={() => this.toggleFilter( "level", "coordinator" )}>
            <Avatar alt="Coordinator" src={this.state.filters_level_coordinator ? coordinator : coordinator_off} sx={{ width: 24, height: 24 }}/>
          </IconButton>
          <IconButton aria-label="Owner" title="Owner" onClick={() => this.toggleFilter( "level", "owner" )}>
            <Avatar alt="Owner" src={this.state.filters_level_owner ? owner : owner_off} sx={{ width: 24, height: 24 }}/>
          </IconButton>
          
        </CardActions>

        <DropDown
          name="Location"
          value={locationSelect}
          doptions={locations}
          hasIcons={false}   
          minWidth={100}
          onDark={true}
          size="small"
          marginTop={8}
          onChangeFunction={this.addLocation}
        />

        <DropDown
          name="Country"
          value={countrySelect}
          doptions={countries}
          hasIcons={false}   
          minWidth={100}
          onDark={true}
          size="small"
          marginTop={8}
          marginLeft={2}
          onChangeFunction={this.addCountry}
        />

        <DropDown
          name="County"
          value={countySelect}
          doptions={counties}
          hasIcons={false}   
          minWidth={100}  
          onDark={true}
          size="small"
          marginTop={8}
          marginLeft={2}
          onChangeFunction={this.addCounty}
        />

        {/* <DropDown
          name="Group"
          value={this.state.locationGroupSelect}
          doptions={locationsGroups}
          hasIcons={false}   
          minWidth={100}  
          onDark={true}
          onChangeFunction={this.addLocationsGroup}
        /> */}
      
        <TextField
          id="standard-basic"
          inputRef={this.searchKeyword}
          label="Keywords"
          variant="standard"
          inputProps={{ sx : { color: "#e3e3e5" } }}
          InputLabelProps={{ sx : { color: "#e3e3e5" } }}
          onChange={(e) => this.searchKey( e )}
          sx={{ color: "#e3e3e5", marginLeft: "32px" }}
        />

        <Typography
          variant="body2"
          color="#e3e3e5"
          sx={{ color: "#e3e3e5", marginTop: "auto", marginLeft: "32px" }}
        >
          Employees: {this.props.employeesCounter}
        </Typography>

        <Typography
          variant="body2"
          color="#e3e3e5"
          sx={{ color: "#e3e3e5", marginTop: "auto", marginLeft: "32px" }}
        >
          Filters: {this.state.filtersCounter}
        </Typography>
        { this.state.filtersCounter > 0 ?
          <IconButton
            aria-label="Remove Filters"
            title="Remove Filters"
            onClick={() => this.resetFilters ( )}
            sx = {{marginTop: "auto"}}
          >
            <Avatar alt="Remove Filters" sx={{ width: 16, height: 16, color: "#99302e" }}>
              <HighlightOffIcon/>
              </Avatar>
          </IconButton>
            : ""
        }

        <ExpandMore
          expand={this.state.expanded}
          onClick={this.handleExpandClick}
          aria-expanded={this.state.expanded}
          aria-label="show more"
          sx={{ color: "#e3e3e5", textAlign: "end" }}
        >
          <ExpandMoreIcon />
        </ExpandMore>
        
      </Box>    

        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Box sx={{ display: 'block', flexDirection: 'row' }}>  
            <CardActions disableSpacing sx={{display: "block", textAlign: "left"}}>
              ...
            </CardActions>         
          </Box>
          
          <Box sx={{ display: 'block', flexDirection: 'row' }}>  
            <CardActions disableSpacing sx={{display: "block", textAlign: "left"}}>
              "aaa"
            

            </CardActions>         
          </Box>

          <Box sx={{ display: 'block', flexDirection: 'row' }}>
          <CardActions disableSpacing sx={{display: "block", textAlign: "left" }}>
                      
            {/* <IconButton aria-label="isDeliverable" title="isDeliverable" onClick={() => this.toggleFilter( "flags", "isDeliverable" )}>
                <Avatar alt="isDeliverable" src={this.state.filters_flags_isDeliverable ? isDeliverable_off : isDeliverable_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="isDiscountable" title="isDiscountable" onClick={() => this.toggleFilter( "flags", "isDiscountable" )}>
                <Avatar alt="isDiscountable" src={this.state.filters_flags_isDiscountable ? isDiscountable_off : isDiscountable_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="isForSale" title="isForSale" onClick={() => this.toggleFilter( "flags", "isForSale" )}>
                <Avatar alt="isForSale" src={this.state.filters_flags_isForSale ? isForSale_off : isForSale_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            
            <IconButton aria-label="isNew" title="isNew" onClick={() => this.toggleFilter( "flags", "isNew" )}>
                <Avatar alt="isNew" src={this.state.filters_flags_isNew ? isNew_off : isNew_on} sx={{ width: 24, height: 24 }}/>
            </IconButton>
           
            <IconButton aria-label="isSellable" title="isSellable" onClick={() => this.toggleFilter( "flags", "isSellable" )}>
                <Avatar alt="isSellable" src={this.state.filters_flags_isSellable ? isSellable_off : isSellable_on} sx={{ width: 24, height: 24 }}/>
            </IconButton> */}
            
            </CardActions>
          </Box>
            
          </CardContent>

        </Collapse>

        {/* </Box> */}




    </Card>   
    );

  }
}

export default EmployeesFilters;