import React from 'react';
import { Avatar, Box, Card, CardActions, CardContent, CardHeader, Divider, List, ListItem, ListItemAvatar, listItemClasses, ListItemText, Stack, SvgIcon, Typography, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import noImage from '../../images/noImage.png';

import Client from '../Client';
import Config from '../ClientConfig';

class OverviewLatestProducts extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      products: [],
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);

  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    let queryData = { "type": 2 }
	let data = this.state.client.getData ( Config.API_URL_REPORTS_PRODUCTS, queryData );
    if ( data ) {
      this.setState ( { products: data.products });
    }
    this.setState ( { filterData: queryData } );
  }

  doSync ( ) {
    this.getData ( );
  }

  render() {
    let productImage = noImage;
		let productsLatest = [];

		if (this.state.products){
			Object.keys(this.state.products).forEach(key => {
				productImage = noImage;
				let fData = this.state.products [ key ];
				if (fData.productImage_product.length > 0) {
					productImage=fData.productImage_product [0].image;
					fData.productImage_product.map((image) => {
						if ( image.isMain )  productImage=image.image;
					});
				}
				let p = {
					id: fData.iref,
					image: productImage,
					name: fData.name,
					updatedAt: fData.createdOn
				}		
				productsLatest.push ( p );   
			});
		}

    return (
      <Card sx={{ borderRadius:"16px", height: '100%' }}>
        <CardHeader title="Latest Products" />
        <List sx={{[`& .active, & .${listItemClasses.root}:hover`]: {color: "#adadad",fontWeight: "bold","& svg": {fill: "#adadad"}}}}>
          {productsLatest.map((product, index) => {
            const hasDivider = index < productsLatest.length - 1;
            //const ago = formatDistanceToNow(product.updatedAt);
            const ago = product.updatedAt;

            return (
              <ListItem
                divider={hasDivider}
                key={product.id}
              >
                <ListItemAvatar>
                  {
                    product.image
                      ? (
                        <Box
                          component="img"
                          src={product.image}
                          sx={{
                            borderRadius: 1,
                            height: 48,
                            width: 48
                          }}
                        />
                      )
                      : (
                        <Box
                          sx={{
                            borderRadius: 1,
                            backgroundColor: 'neutral.200',
                            height: 48,
                            width: 48
                          }}
                        />
                      )
                  }
                </ListItemAvatar>
                <ListItemText
                  primary={product.name}
                  primaryTypographyProps={{ variant: 'subtitle1' }}
                  secondary={`Updated ${ago}`}
                  secondaryTypographyProps={{ variant: 'body2' }}
                />
                <IconButton edge="end">
                  <SvgIcon>
                    <MoreVertIcon />
                  </SvgIcon>
                </IconButton>
              </ListItem>
            );
          })}
        </List>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            color="inherit"
            endIcon={(
              <SvgIcon fontSize="small">
                <ArrowForwardIcon />
              </SvgIcon>
            )}
            size="small"
            variant="text"
          >
            View all
          </Button>
        </CardActions>
      </Card>
    );

  }
}

export default OverviewLatestProducts;


 {/* <Button
                color="inherit"
                size="small"
                startIcon={(
                <SvgIcon fontSize="small">
                    <AutorenewIcon />
                </SvgIcon>
                )}
                onClick={() => this.doSync()}
            /> */}