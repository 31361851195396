import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser } from "react-icons/fa";
import Skeleton from '@mui/material/Skeleton';

//import './css/sideBar.scss';
import './css/elements.css';
import './css/dashboard.css';

import Client from './components/Client';
import Config from './components/ClientConfig';
//import Product from './components/Product';
import User from './components/User';
import UsersFilters from './components/UsersFilters';



let theme = createTheme();

class UsersClients extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      users: null,
      //locationsGroups: null,
      addNewUser: false,
      queryData: {},
      countrySelected: "country061",
    };

    const { loading = false } = props;
    this.queryData = { 
      "user_type_gte": "1",
      "user_type_lte": "14",
      "limit": "20",
    };
    this.applyFilters = this.applyFilters.bind(this);    
    this.toggleAdd = this.toggleAdd.bind(this);
    this.userUpdated = this.userUpdated.bind(this);
    this.getData = this.getData.bind(this);
  }

  getData ( ) {
    let queryData = {};
    queryData [ "user_type_gte" ] = "1";
    queryData [ "user_type_lte" ] = "14";
    queryData [ "limit" ] = "20";
    this.setState ( { queryData: queryData } );
    this.queryData = queryData;
    queryData [ "country" ] = this.state.countrySelected;
    let data = this.state.client.getData ( Config.API_URL_USERS_PROFILES, queryData );
    this.setState ( { users: data.usersProfiles });
  }

  componentDidMount() {
    this.getData ( );
  }

  componentWillUnmount() {    
  }

  //Function to change the main Products filter state
  applyFilters ( queryData ) {
    this.setState ( { users: [] });
    if ( queryData ) {
      let data = this.state.client.filterUsers ( queryData );
      this.setState ( { users: data.usersProfiles });
      this.setState ( { queryData: queryData } );
    }
  }

 
  toggleAdd ( buttonState ) {
    let doAdd = this.state.addNewUser;
    if ( buttonState !== undefined ) doAdd = buttonState;
    this.setState ( {addNewUser: doAdd} );
  }

  userUpdated ( ) {
    this.setState ( { addNewUser: false } );
    this.applyFilters ( this.state.queryData );
    this.toggleAdd ( false );
    this.getData ( );
    window.scrollTo(0, 0);
  }

  render() {
    let usersList;
    let usersCounter = 0;
    if ( this.state.users ) {
      usersCounter = this.state.users.length;
      usersList = this.state.users.map ( (user) => {
        return <User
          key={user.iref}
          row={user}
          userUpdated={this.userUpdated}
          onlyClient={true}
          /* firstName={user.first_name}
          lastName={user.last_name}
          email={user.email}
          username={user.username}
          type={user.user_type}
          status={user.user_status}
          person={user.person} */
        />
      });
    }

    let geoData = {};
    if ( this.props.geoData ) geoData = this.props.geoData;
    //let aa = Object.entries ( geoData );

    let countries = {};
    let counties = {};
    Object.keys(geoData).forEach(key => {
      let fData = geoData [ key ];
      countries [ key ] = fData.name;
      Object.keys ( fData ).forEach( fKey => {
        if ( fKey !== "name" ) counties [ fKey ] = fData [ fKey ].name;
      });
    });

    let groups = {};
    let locationsGroups = {};

    let queryData = { };
    if ( this.queryData ) queryData = this.queryData;

    return (
 
      <div style={{ backgroundColor: "#273442", height: "100%" }}>
        <div className='sticky'>

          <UsersFilters
            applyFilters = {this.applyFilters}            
            toggleAdd = {this.toggleAdd}
            usersCounter = {usersCounter}
            countries = {countries}
            counties = {counties}
            queryData = {queryData}
            sx={{ position: 'sticky', top: 0}}
            addNewUser = {this.state.addNewUser}
          />

        </div>

        { this.state.addNewUser ?
        <User
          key="0"
          row={{}} 
          newUser={true}
          userUpdated={this.userUpdated}
          onlyClient={true}
        />
        : ""
        }

        {this.loading ? <Skeleton animation="wave" variant="rounded" width={800} height={400} /> : usersList }

      </div>

    );

  }
}

export default UsersClients;