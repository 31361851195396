import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser, FaAssistiveListeningSystems } from "react-icons/fa";
import Skeleton from '@mui/material/Skeleton';
import swal from 'sweetalert';
import Allergen from './Allergen';

//import './css/sideBar.scss';
//import './css/dashboard.css';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import KImagesTable from './KImagesTable';
import KProductsTable from './KProductsTable';
import TextField from '@mui/material/TextField';
import DropDown from './DropDown';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import noImage from './../images/noImage.png';
import idle from './../images/icons/idle_on.png';
import registered from './../images/icons/registered_on.png';
import active from './../images/icons/active_on.png';
import discontinued from './../images/icons/discontinued_on.png';

import soia_off from './../images/icons/soia_off.png';
import soia_on from './../images/icons/soia_on.png';
import arahide_off from './../images/icons/arahide_off.png';
import arahide_on from './../images/icons/arahide_on.png';
import dioxid_off from './../images/icons/dioxid_off.png';
import dioxid_on from './../images/icons/dioxid_on.png';
import grau_off from './../images/icons/grau_off.png';
import grau_on from './../images/icons/grau_on.png';
import lapte_off from './../images/icons/lapte_off.png';
import lapte_on from './../images/icons/lapte_on.png';
import mustar_off from './../images/icons/mustar_off.png';
import mustar_on from './../images/icons/mustar_on.png';
import oua_off from './../images/icons/oua_off.png';
import oua_on from './../images/icons/oua_on.png';
import peste_off from './../images/icons/peste_off.png';
import peste_on from './../images/icons/peste_on.png';
import porumb_off from './../images/icons/porumb_off.png';
import porumb_on from './../images/icons/porumb_on.png';
import seminte_off from './../images/icons/seminte_off.png';
import seminte_on from './../images/icons/seminte_on.png';

import isDeliverable_off from './../images/icons/isDeliverable_off.png';
import isDeliverable_on from './../images/icons/isDeliverable_on.png';
import isDiscountable_off from './../images/icons/isDiscountable_off.png';
import isDiscountable_on from './../images/icons/isDiscountable_on.png';
import isForSale_off from './../images/icons/isForSale_off.png';
import isForSale_on from './../images/icons/isForSale_on.png';
import isNew_off from './../images/icons/isNew_off.png';
import isNew_on from './../images/icons/isNew_on.png';
import isSellable_off from './../images/icons/isSellable_off.png';
import isSellable_on from './../images/icons/isSellable_on.png';
import canGenerateFidelityPoints_off from './../images/icons/canGenerateFidelityPoints_off.png';
import canGenerateFidelityPoints_on from './../images/icons/canGenerateFidelityPoints_on.png';

import PermMediaIcon from '@mui/icons-material/PermMedia';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import CategoryIcon from '@mui/icons-material/Category';
import ClassIcon from '@mui/icons-material/Class';

import BackOfficeConfig from './../Config';
import Client from './Client';
import Config from './ClientConfig';

let theme = createTheme();

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));



class Combo extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      combo: {
        iref: "",
        name: "",
        sku: "",
        ean: "",
        pClass: {},
        product: [],
        createdOn: "",
        modifiedOn: "",
        description: "",
        isDiscountable: false,
        isDeliverable: false,
        isSellable: false,
        isForSale: false,
        isNew: false,
        status: false,
      },
      expanded: false,
      edit: false,
      saveButtonVisible: false,
      //combo_mainImage: null,
      name_valid: false,
      description_valid: false,
      ean_valid: false,
      sku_valid: false,
      class_valid: false,
      classes: [],
      aclasses: [],
      detailsVisible: false,
      tabValue: "images",
    };

    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.getElementByName = this.getElementByName.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.getClasses = this.getClasses.bind(this);
    this.changeClass = this.changeClass.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.flagOption = this.flagOption.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.pushData = this.pushData.bind(this);
    this.imageUpdated = this.imageUpdated.bind(this);
    this.productUpdated = this.productUpdated.bind(this);
  }

  componentDidMount ( ) {
    if ( this.props.combo ) {
      let cmb = this.props.combo;
      this.setState ( { combo: cmb } );
      if ( cmb.name.length > 0 )
        this.setState ( { name_valid: true } );
      if ( cmb.description.length > 0 )
        this.setState ( { description_valid: true } );
      if ( cmb.ean.length > 0 )
        this.setState ( { ean_valid: true } );
      if ( cmb.sku.length > 0 )
        this.setState ( { sku_valid: true } );

      if ( cmb.pClass !== undefined )
        this.setState ( { class_valid: true } );

      this.setState ( { detailsVisible: true } );
    }

    if ( this.props.isNew === true )
        this.handleEditClick ( );

    if ( this.props.allergensList )
      this.setState ( { allergensList: this.props.allergensList } );
  }

  componentDidUpdate ( prevProps ) {
    if ( this.props != prevProps ) {
      if ( this.props.combo ) {

        let cmb = this.props.combo;
        this.setState ( { combo: cmb } );

        if ( cmb.name.length > 0 )
          this.setState ( { name_valid: true } );
        if ( cmb.description.length > 0 )
          this.setState ( { description_valid: true } );
        if ( cmb.ean.length > 0 )
          this.setState ( { ean_valid: true } );
        if ( cmb.sku.length > 0 )
          this.setState ( { sku_valid: true } );

        if ( cmb.pClass !== undefined )
          this.setState ( { class_valid: true } );
      }


      if ( this.props.allergensList )
        this.setState ( { allergensList: this.props.allergensList } );
    }
  }

  handleExpandClick ( ) {
    this.setState({ expanded: !this.state.expanded });
  }

  handleEditClick ( ) {
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
    if ( this.state.expanded === false && cEdit === true )
      this.state.expanded = true;
    if ( this.state.expanded === true && cEdit === false )
      this.state.expanded = false;
    this.setState({ saveButtonVisible: cEdit });

    this.getClasses ( );
  }

  handleTabChange ( event, newValue ) {
    this.setState ( { tabValue: newValue } );
  }

  getElementByName ( elements, name ) {    
    let e = {
      "iref" : null,
      "name" : "X",
      "image" : "X",
      "image_off" : "X"
    };
    if ( elements !== null && elements !== undefined ) {
      for ( var i = 0; i < elements.length; i++ ) {
        let el = elements [ i ];
        if ( el.length === 2 ) {
          if ( el [ 1 ].name === name ) e = el [ 1 ];
        } else {
          if ( el [ 0 ].name === name ) e = el [ 0 ];
        }        
      }
    }
    return e;    
  }

  changeStatus ( e ) {
    let uCombo = this.state.combo;
    uCombo.status = parseInt ( e );
    this.setState ( { combo: uCombo } );
    this.setState ( { somethingHasChanged: true } );
  }

  getClasses ( ) {
    let data = this.state.client.getProductsClasses ( );
    if ( data ) {
      let classes = {};
      for ( var i = 0; i < data.productClasses.length; i++ ) {
        classes [ data.productClasses [ i ].iref ] = {          
          "name": data.productClasses [ i ].name,
          "image": data.productClasses [ i ].image
          }
      }
      this.setState ( { classes: classes } );
      this.setState ( { aclasses: data.productClasses } );
    }
  }

  changeClass ( e ) {
    let classSet = false;
    let uCombo = this.state.combo;
    for ( var i = 0; i < this.state.aclasses.length; i++ ) {
      if ( this.state.aclasses [ i ].iref === e ) {
        uCombo.pClass = this.state.aclasses [ i ];
        classSet = true;
      }
    }
    this.setState ( { combo: uCombo } );
    if ( classSet === true )
      this.setState ( { class_valid: true } );
    else
      this.setState ( { class_valid: false } );
    this.setState ( { somethingHasChanged: true } );
  }

  changeValue ( e ) {
    let id = null;
    if ( e.target.id !== null ) id = e.target.id;
    let value = null;
    if  ( e.target.value !== null ) value = e.target.value;
    if ( id !== null) {
      let uCombo = this.state.combo;
      switch ( id ) {
        case "name":
          uCombo.name = value;
          if ( value.length > 2 ) this.setState ( { name_valid: true } );
          else this.setState ( { name_valid: false } );
          break;
        case "description":
          uCombo.description = value;
          if ( value.length > 2 ) this.setState ( { description_valid: true } );
          else this.setState ( { description_valid: false } );
          break;
        case "ean":
          uCombo.ean = value;
          if ( value.length > 0 ) this.setState ( { ean_valid: true } );
          else this.setState ( { ean_valid: false } );
          break;
        case "sku":
          uCombo.sku = value;
          if ( value.length > 0 ) this.setState ( { sku_valid: true } );
          else this.setState ( { sku_valid: false } );
          break;
      }

      this.setState ( { combo: uCombo } );
      this.setState ( { somethingHasChanged: true } );
    }
  }

  flagOption ( e ) {
    let option = e.target.alt;
    let uCombo = this.state.combo;
    uCombo [ option ] = !uCombo [ option ];
    this.setState ( { combo: uCombo } );
    this.setState ( { somethingHasChanged: true } );
  }

  pushData ( e ) {

    if ( this.state.edit && this.state.somethingHasChanged && this.state.name_valid && this.state.description_valid &&
      this.state.sku_valid && this.state.class_valid ) {
      
      let uCombo = this.state.combo;
      let allergensNew = [];
      if ( uCombo.allergens ) {
        for ( var i = 0; i < uCombo.allergens.length; i++ ) {   
          let iref = null;
          if ( uCombo.allergens [ i ].iref ) iref = uCombo.allergens [ i ].iref;
          else iref = uCombo.allergens [ i ];
          if ( iref ) allergensNew.push ( iref );
        }
      }
      uCombo [ "allergensNew" ] = allergensNew;
      uCombo.modifiedOn = "";
      uCombo.createdOn = "";

      let rdata = this.state.client.pushData ( Config.API_URL_COMBO_ADD, uCombo );
      if ( rdata ) {
        if ( rdata.status == "OK" ) {
          uCombo = this.state.combo;
          uCombo.iref = rdata.iref;
          this.setState ( { combo: uCombo } );
          this.handleEditClick ( );
          swal ( "Success", "Successfully added/modified combo ! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            this.props.comboUpdated ( );
            this.setState ( { somethingChanged: false } );
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Add/Update combo failed !", rdata.message , "error" );
        }
      }
    } else {
      swal ( "Error", "Add/Update combo failed !", "error" );
    }
  }

  imageUpdated ( ) {
    let queryData = {
      iref: this.state.combo.iref
    }
    let rdata = this.state.client.getData ( Config.API_URL_COMBO + this.state.combo.iref, queryData );
    if ( rdata ) {
      if ( rdata.comboImage_productCombo ) {
        let uCombo = this.state.combo;
        uCombo.comboImage_productCombo = rdata.comboImage_productCombo;
        this.setState ( { product: uCombo } );
      }
    }
  }

  productUpdated ( ) {
    let queryData = {
      iref: this.state.combo.iref
    }
    let rdata = this.state.client.getData ( Config.API_URL_COMBO + this.state.combo.iref, queryData );
    if ( rdata ) {
      if ( rdata.comboImage_productCombo ) {
        let uCombo = this.state.combo;
        uCombo.comboImage_productCombo = rdata.comboImage_productCombo;
        this.setState ( { product: uCombo } );
      }
    }
  }

  render() {
    let combo_name = "";
    let combo_description = "";
    let combo_image = noImage;
    let combo_ean = "";
    let combo_sku = "";
    let combo_modifiedOn = "";
    let combo_class_name = "";
    let combo_class_image = noImage;
    let combo_status = 0;
    let combo_status_image = noImage;
    let combo_status_alt = "Unknown"
    let combo_class_iref = "";
    let isDeliverable = isDeliverable_off;
    let isDiscountable = isDiscountable_off;
    let isForSale = isForSale_off;
    let isNew = isNew_off;
    let isSellable = isSellable_off;
    let canGenerateFidelityPoints = canGenerateFidelityPoints_off;

    let comboImages = [];
    let comboProducts = [];

    if ( this.state.combo ) {
      let combo = this.state.combo;
      if ( combo.status ) combo_status = combo.status;    
      if ( combo.name ) combo_name = combo.name;    
      if ( combo.description ) combo_description = combo.description;
      if ( combo.modifiedOn ) combo_modifiedOn = combo.modifiedOn;
      if ( combo.ean ) combo_ean = combo.ean;
      if ( combo.sku ) combo_sku = combo.sku;

      if ( combo.pClass ) {
        combo_class_iref = combo.pClass.iref;
        if( combo.pClass.name && combo.pClass.name !== "null" )
          combo_class_name = "Product Class: " + combo.pClass.name;
        if( combo.pClass.image && combo.pClass.image !== "null" )
        combo_class_image = combo.pClass.image;
      }

      switch ( combo.status ) {
        case 0:
          combo_status_image = registered;
          combo_status_alt = "Registered";
          break;
        case 1:
          combo_status_image = idle;
          combo_status_alt = "Idle";
          break;
        case 2:
          combo_status_image = active;
          combo_status_alt = "Active";
          break;
        case 9:
          combo_status_image = discontinued;
          combo_status_alt = "Discontinued";
          break;
      }

      if ( combo.isDeliverable === true ) isDeliverable = isDeliverable_on;
      if ( combo.isDiscountable === true ) isDiscountable = isDiscountable_on;
      if ( combo.isForSale === true ) isForSale = isForSale_on;
      if ( combo.isNew === true ) isNew = isNew_on;
      if ( combo.isSellable === true ) isSellable = isSellable_on;
      if ( combo.canGenerateFidelityPoints === true ) canGenerateFidelityPoints = canGenerateFidelityPoints_on;

      let comboHasImage = false;
      if ( combo.comboImage_productCombo ) {
        if ( combo.comboImage_productCombo.length > 0 ) {
          comboHasImage = true;
          if ( combo.comboImage_productCombo[0].image )
            combo_image = combo.comboImage_productCombo[0].image;
          comboImages = combo.comboImage_productCombo.map ( (pImage) => {
            if ( pImage.isMain === true )
              combo_image = pImage.image;
          });
        //Former KImages table        
        }
      }

      comboImages = <KImagesTable
                      images = {combo.comboImage_productCombo}
                      edit = {this.state.edit}
                      viewDetails = {this.state.viewDetails}
                      saveURL = {Config.API_URL_COMBO_ADD_IMAGE}
                      removeURL = {Config.API_URL_COMBO_REMOVE_IMAGE}
                      parentIref = {combo.iref}
                      imageUpdated = {this.imageUpdated}
                    />;


      if ( comboHasImage ) {
        let fileName = combo_image.replace(/\.[^/.]+$/, "")
        let fileExtensions = combo_image.split(".").pop();
        combo_image = fileName + "_m." + fileExtensions;
      }
      //console.log ( combo_image );

      comboProducts = <KProductsTable
                        products = {combo.combo_productComboProduct}
                        edit = {this.state.edit}
                        viewDetails = {this.state.viewDetails}
                        saveURL = {Config.API_URL_COMBO_ADD_PRODUCT_TO_COMBO}
                        removeURL = {Config.API_URL_COMBO_REMOVE_PRODUCT_FROM_COMBO}
                        parentIref = {combo.iref}
                        productUpdated = {this.productUpdated}
                      />;
    }
    
    /* if ( this.props.image ) {
      combo_image = this.props.image;
      let fileName = combo_image.replace(/\.[^/.]+$/, "");
      let fileExtensions = combo_image.split(".").pop();
      combo_image = fileName + "_m." + fileExtensions;

    } */

    let allergens;
    let allergensRender = "";

    if ( this.props.allergensList ) {
      let allergensArr = Object.entries ( this.props.allergensList );      
      allergensRender = allergensArr.map ( (allergen) => {
        let img_on = "";
        let img_off = "";
        let name = "";
        let img = "";
        let iref = "";
        if ( allergen.length === 2 ) {          
          iref = allergen [ 1 ].iref;
          name = allergen [ 1 ].name;
          img_on = allergen [ 1 ]. image;
          img_off = allergen [ 1 ]. image_off;
        } else {
          iref = allergen [ 0 ].iref;
          name = allergen [ 0 ].name;
          img_on = allergen [ 0 ]. image;
          img_off = allergen [ 0 ]. image_off;
        }
        img = img_off;
        if ( this.props.allergens ) {
          allergens = this.props.allergens.map ( (allergen) => {
            if ( allergen.iref === iref ) img = img_on;
          });
        }
      return <Allergen
        key = {name}
        name = {name}
        image = {img}
        />
      });
    }

    
    /* if ( this.props.images ) {
      if ( this.props.images.length > 0 ) {
        comboImages = this.props.images.map ( (pImage) => {
          return <CardMedia
          component="img"
          height="128"
          sx={{ width: 128, marginLeft: 1 }}
          image={pImage.image}
          alt={pImage.name}
        />
        });
      }
    } */

    let tabValue = "images";
    if ( this.state.tabValue ) tabValue = this.state.tabValue;

    let saveButtonVisible = false;
    if ( this.state.edit && this.state.somethingHasChanged && 
        this.state.name_valid && this.state.description_valid &&
        this.state.sku_valid && this.state.class_valid )
        saveButtonVisible = true;

    return (
      /* , display: 'flex' */
    <Card sx={{ borderRadius: '16px', m: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardHeader
          avatar={          
            <Avatar alt={combo_status_alt} src={combo_status_image} title={combo_status_alt}>
            </Avatar>            
          }        
          action={            
            <IconButton aria-label="settings" onClick={this.handleEditClick}>
              <EditIcon
                sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}
              />
            </IconButton>
          }
          title={combo_name}
          subheader=""
          titleTypographyProps={{variant:'h5' }}
        />

        <Box sx={{ display: 'flex', flexDirection: 'row' }}>

        <CardMedia
          component="img"
          height="120"
          sx={{ width: 128, marginLeft: 1 }}
          image={combo_image}
          alt={combo_name}
        />

      {this.state.edit ?
        <Box sx={{ flexDirection: 'column', display: "block", width: "30%", marginLeft: 1, marginBottom: 1 }}>
          <DropDown
            name="Status"
            value={combo_status !== undefined ? combo_status : ''}
            doptions={BackOfficeConfig.PRODUCT_STATUSES_WITH_ICONS}
            hasIcons={true}   
            minWidth={220}
            maxWidth={220}
            onDark={false}
            onChangeFunction={this.changeStatus}
            marginTop={8}
            marginLeft={0}
            margin={0}
          />
          <DropDown
            name="Class"
            value={combo_class_iref !== undefined ? combo_class_iref : ''}
            doptions={this.state.classes}
            hasIcons={true}   
            minWidth={220}
            maxWidth={220}
            onDark={false}
            onChangeFunction={this.changeClass}
            marginTop={8}
            marginLeft={0}
            margin={0}
            error={this.state.class_valid === false}
          />
          <TextField
            id="ean"
            label="EAN"
            value={combo_ean || ''}
            variant="outlined"
            onChange={this.changeValue}
            sx={{minWidth: "25%", marginTop: 1, marginLeft: 0}}
            onFocus={event => { event.target.select(); }}
            inputProps={{maxLength:"100"}}
          />
          <TextField
            id="sku"
            label="SKU"
            value={combo_sku || ''}
            variant="outlined"
            onChange={this.changeValue}
            sx={{minWidth: "25%", marginTop: 1, marginLeft: 0}}
            onFocus={event => { event.target.select(); }}
            inputProps={{maxLength:"255"}}
            error={this.state.sku_valid === false}
          />
        </Box>
        :
        <Box sx={{ flexDirection: 'column', display: "block" }}>
          <CardContent sx={{textAlign: "left"}}>
            <Typography variant="body2" color="text.secondary">
              {combo_description}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {combo_ean ? "EAN: " + combo_ean : ""}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {combo_sku ? "SKU: " + combo_sku : ""}
            </Typography>
          </CardContent>


          {/* <CardActions disableSpacing sx={{display: "flex", justifyContent: "flex-end"}}> */}
            <IconButton aria-label={combo_class_name} title={combo_class_name}>       
              <Avatar alt={combo_class_name} src={combo_class_image} sx={{ width: 48, height: 48 }}/>
            </IconButton>
          {/* </CardActions> */}
        </Box>
        }

        <Box sx={{ flexDirection: 'column', justifyContent:"right", width: '100%' }}>

          {this.state.edit ?
            <Box sx={{ display: "flex", flexDirection: 'row', justifyContent:"right", width: '100%' }}>
              <TextField
                id="name"
                key={combo_name}
                label="Name"
                value={combo_name || ''}
                variant="outlined"
                onChange={this.changeValue}
                sx={{minWidth: "30%", marginLeft: 2, marginTop: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"100"}}
                error={this.state.name_valid === false}                
              />
              <TextField
                id="description"
                key={combo_description}
                label="Description"
                value={combo_description || ''}
                variant="outlined"
                onChange={this.changeValue}
                sx={{minWidth: "30%", marginTop: 1, marginLeft: 1, marginRight: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"1024"}}
                error={this.state.description_valid === false}
              />
            </Box>
            : ""
          }
        
          <CardActions disableSpacing sx={{display: "block", textAlign: "end"}}>
            {allergensRender}
          </CardActions>
          
          <CardActions disableSpacing sx={{display: "block", textAlign: "end" }}>          
            
            {/* <IconButton aria-label="canGenerateFidelityPoints" title="canGenerateFidelityPoints">
                <Avatar alt="canGenerateFidelityPoints" src={canGenerateFidelityPoints} sx={{ width: 24, height: 24 }}/>
            </IconButton> */}

            
            <IconButton aria-label="isDeliverable" title="isDeliverable" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="isDeliverable" src={isDeliverable} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="isDiscountable" title="isDiscountable" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="isDiscountable" src={isDiscountable} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton aria-label="isForSale" title="isForSale" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="isForSale" src={isForSale} sx={{ width: 24, height: 24 }}/>
            </IconButton>            
            <IconButton aria-label="isNew" title="isNew" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="isNew" src={isNew} sx={{ width: 24, height: 24 }}/>
            </IconButton>            
            <IconButton aria-label="isSellable" title="isSellable" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="isSellable" src={isSellable} sx={{ width: 24, height: 24 }}/>
            </IconButton>
            <IconButton id="canGenerateFidelityPoints" aria-label="canGenerateFidelityPoints" title="canGenerateFidelityPoints" onClick={this.flagOption} disabled={!this.state.edit}>
                <Avatar alt="canGenerateFidelityPoints" src={canGenerateFidelityPoints} sx={{ width: 24, height: 24 }}/>
            </IconButton>

          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "middle", justifyContent:"center", alignItems: "center", paddingTop: "8px" }}>
            {this.state.edit && saveButtonVisible ?
              <Button
                variant="contained"
                size="small"
                sx={{ color: "#e3e3e5" }}
                startIcon={<SaveIcon />}
                onClick={this.pushData}
              >
                Save
              </Button>
              : combo_modifiedOn ? "Modified on: " + combo_modifiedOn : ""
            }
          </Box>

          
          <ExpandMore
            expand={this.state.expanded}
            onClick={this.handleExpandClick}
            aria-expanded={this.state.expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>

        </Box>
        </Box>

        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent sx={{paddingTop: "2px"}}>

            { this.state.detailsVisible ?
            <Box sx={{ width: '100%' }}>
              <Tabs
                value={tabValue}
                onChange={this.handleTabChange}
                centered
                indicatorColor="secondary"
              >
                <Tab label="Images" value="images" icon={<PermMediaIcon />} iconPosition="start"/>
                <Tab label="Products" value="products" icon={<FastfoodIcon />} iconPosition="start"/>
                {/* <Tab label="Classes" value="classes" icon={<ClassIcon />} iconPosition="start"/> */}
                <Tab label="Sub Categories" value="subcategories" icon={<CategoryIcon />} iconPosition="start"/>
              </Tabs>
            </Box>
          : ""
          }

          { this.state.detailsVisible  && this.state.tabValue === "images" ?
            comboImages
          : ""
          }

          { this.state.detailsVisible  && this.state.tabValue === "products" ?
            comboProducts
          : ""
          }

              {/* {comboImages} */}         
          </CardContent>
        </Collapse>

        </Box>

        

      {/* </Box> */}
    </Card>
   
    );

  }
}

export default Combo;
