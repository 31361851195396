import React from 'react';
import { Avatar, Box, Card, CardContent, CardHeader, Stack, SvgIcon, Typography, Button } from '@mui/material';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';

import Chart from './chart';

import Client from '../Client';
import Config from '../ClientConfig'; 
  
class ClientsByUrban extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      byUrban: {},
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);

  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    let queryData = { "type": 1 }
    let data = this.state.client.getData ( Config.API_URL_REPORTS_GEOGRAPHIC, queryData );
    if ( data ) {
      this.setState ( { byUrban: data });
    }
    this.setState ( { filterData: queryData } );
  }

  doSync ( ) {
    this.getData ( );
  }

  render() {

		let urbanLabels = [];
		let urbanData = [];

		Object.keys(this.state.byUrban).forEach(key => {
			let fData = this.state.byUrban [ key ];			
			urbanLabels.push ( key );
			urbanData.push ( fData );     
		});

    return (
        <Card style={{ height: '500px' }} sx={{ borderRadius:"16px", height: '100%' }}>
          <CardHeader title="Clients By Urban" />
          <CardContent>
            <Chart
              height={300}
              type="pie"
              series={urbanData}
              labels={urbanLabels}
              width="100%"
            />
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="center"
              spacing={2}
              sx={{ mt: 2 }}
            >
              {urbanData.map((item, index) => {
                const label = urbanLabels[index];

                return (
                  <Box
                    key={label}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: "100%",
                      borderRadius: "16px",
                      justifyContent: "center"
                    }}
                  >
                  <Typography
                      sx={{ my: 1 }}
                      variant="h8"
                    >
                      {label}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="subtitle2"
                    >
                      {item}
                    </Typography>  
                  </Box>
                );
              })}
            </Stack>
          </CardContent>
        </Card>
    );

  }
}

export default ClientsByUrban;