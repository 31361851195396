import $ from 'jquery';
import Config from './ClientConfig';
import checkLocalStorage from './checkLocalStorage';
import swal from 'sweetalert';

import BackOfficeConfig from './../Config';

//import { Exception } from 'sass';
var sprintf = require('sprintf-js').sprintf;

export function isAuthTokenValid(token){
    var tokenMeta = JSON.parse(atob(token.split(".")[1]));

    var expiry = new Date(tokenMeta.exp  * 1000);
    var current = new Date();

    if (current>expiry){
        return false;
    } else {
        return true;
    }
}

export default class Client {
    constructor (){
        this.accessToken = "";
        this.refreshToken = "";
        //this.prefix = window.location.protocol + "//" + window.location.host;
        //this.prefix = BackOfficeConfig.BASE_URL; //"https://cartos.kalopsia.pro/";
        this.prefix = BackOfficeConfig.BASE_API_URL; //"https://cartos.kalopsia.pro/";
        //debugger;
        this.user = {};
        // if (window.location.href.indexOf(':8080') !== 0) {
        //     this.prefix = window.location.href.replace(':8080',":8000")
        // }
        // var idots = window.location.href.indexOf(':', 7 );
        // var islash = window.location.href.indexOf('/', 7 );
        // this.prefix = window.location.href.substr ( 0, islash );
        
        // localStorage.setItem("apiUser", JSON.stringify({"aaaa":"bbbb"}));
        //if there is no support for localStorage set it up using cookies
        checkLocalStorage();
        if (localStorage.length > 0){
            var data = JSON.parse(localStorage.getItem("apiUser"));
            if (data !== null && data.accessToken !== undefined && isAuthTokenValid(data.accessToken)){
                this.profile = data.profile;
                /* this.user = {
                    "first_name": data.user.first_name,
                    "last_name": data.user.last_name,
                    "username": data.user.username,
                    "user_type": data.user.user_type,
                    "email": data.user.email,
                    "phone": data.user.phone,
                    "city": data.user.city,
                    "postal_code": data.user.postal_code
                } */
                this.accessToken = data.accessToken;
                this.refreshToken = data.refreshToken;
            } else {
                localStorage.clear()
            }
        }

        var self = this;
        
        $.ajaxSetup({
            crossDomain:true,
            beforeSend: function(xhr) {
                    if (self.getAuthToken().length > 0)
                xhr.setRequestHeader('Authorization', 'Bearer ' + self.getAuthToken());
            }
        })
    };

    // fuck this!
    getAuthToken () {
        return this.accessToken;
    }

    isAccessTokenValid ( token ) {
        return isAuthTokenValid ( token );
    }

    auth (username, password, callback = function(){}) {
        //console.log ( "========= AUTH ============" );
        //debugger;
        var data = {
            "username": username,
            "password": password
        };
        var that = this;
        /* console.log ( data );
        console.log ( that.prefix + " - " + Config.AUTH_API_URL );
        console.log ( BackOfficeConfig.DASHBOARD_URL ); */
        //debugger;
        $.ajax({
            url: that.prefix + Config.AUTH_API_URL,
            data: JSON.stringify(data),
            type: "POST",
            contentType: 'application/json',
            processData: false,
            async: true,
            success:function(data){
                if ("access" in data){
                    that.accessToken = data["access"];
                    that.refreshToken = data["refresh"];
                    $.ajax({
                        url: that.prefix + Config.WHOAMI_API_URL,
                        type: "GET",
                        contentType: 'application/json',
                        processData: true,
                        async: true,
                        success:function(data){
                            that.profile = data.profile;
                            /* that.user = {
                                "first_name": data.first_name,
                                "last_name": data.last_name,
                                "username": data.username,
                                "user_type": data.user_type,
                                "email": data.email,
                                //"phone": data.apiUserprofile ? data.apiUserprofile.mobile_phone_number : null,
                                "user_status": data.user_status,
                                "person": data.person
                            } */
                            //console.log(that.user);
                            localStorage.setItem("apiUser", JSON.stringify({
                                "profile": that.profile,
                                "accessToken": that.accessToken,
                                "refreshToken": that.refreshToken,
                                "time": (new Date()).getTime(),
                                }));
                            //window.location.reload();
                            //window.location.href=that.prefix + "backoffice/Dashboard";
                            window.location.href = BackOfficeConfig.DASHBOARD_URL;
                        },
                        error: function(xhr, status, error){
                            that.logout();
                        }
                    })
                }
            },
            error: function(xhr, status, error){                
                swal({
                    text: "Wrong username and/or password !",
                    icon: "error",
                  });
                /* var response = xhr.responseJSON;
                if ("non_field_errors" in response){
                    // swal(response["non_field_errors"])
                    swal({
                        text: "Wrong username and/or password !",
                        icon: "error",
                      });
                } */
            }
        })
    }

    logout (callback = function(){}) {
        if (localStorage.length > 0 || this.user.username){
            var that = this;
            $.ajax({
                url: that.prefix + Config.LOGOUT_API_URL,
                type: "get",
                contentType: 'application/json',
                processData: false,
                async: false,
                success: function(){
                    that.user = {};
                    var data = JSON.parse(localStorage.getItem("apiUser"));
                    localStorage.removeItem("apiUser");
                    //window.location.href =  that.prefix;
                },
                error: function(xhr, status, error){
                    //window.location.href = that.prefix;
                }
            })
        }
    }

    // list_users_test () {
    //     var that = this;
    //     $.ajax({
    //         url: that.prefix + Config.USER_API_URL
    //     })
    // }

    reset () {
        this.accessToken = "";
        this.refreshToken = "";
        this.prefix = window.location.protocol + "//" + window.location.host;
        this.user = {};
    }


/*
    Get a list of ads by using filter data
    - filter_data is a JSON object with the following keys (any other key is ignored):
        "price_min", "price_max", "surface_min", "surface_max", "pieces_min", 
        "pieces_max", "price_min", "price_max", "title", "sort"

    - valid "sort" values are the following:
        price_min || price_max || surface_min || surface_max || piece_min || piece_max || bedroom_min || bedroom_max || title || sort || bathroom_min || bathroom_max || isrecent || has3d || construction_type || type || package || box || creation_date

    - function call example:
        c.getAds({"price_min":50, "price_max":450, "surface_max":300, "sort":"pieces__desc"})
    
*/

    getConfig ( queryData, callback = function(){} ) {
        var that = this;
        var result = null;
        $.ajax( {
            async: false,
            data: queryData,
            type: 'GET',
            url: that.prefix + Config.API_URL_CONFIG_FRONT,
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            },
            contentType: 'application/json',
            cache: false,
            format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("getting Data failed: " + error);
            }
        } );
        return result;
    }

    setConfigLocalStorage ( callback = function(){} ) {
        let queryData = { };
        let cData = this.getConfig ( queryData );
        let platform = { };
        let identityTexts = { };
        let identityImages = { };
        Object.keys(cData).forEach(key => {
            let fData = cData [ key ];
            switch ( key ) {
            case "platform":
                fData.map ( (d) => {
                platform [ d.key ] = d.value;
                });
                break;

            case "identityTexts":
                fData.map ( (d) => {
                identityTexts [ d.key ] = d.value;
                });
                break;

            case "identityImages":
                fData.map ( (d) => {
                identityImages [ d.key ] = d.image;
                });
                break;
            }     
        });
        let config = { };
        config [ "platform" ] = platform;
        config [ "identityTexts" ] = identityTexts;
        config [ "identityImages" ] = identityImages;
        localStorage.setItem ( "config",  JSON.stringify(config) );
    }

    setLanguagesLocalStorage ( callback = function(){} ) {
        var that = this;
        var result = null;
        $.ajax( {
            async: false,
            type: 'GET',
            data: "",
            url: that.prefix + Config.API_URL_LANGUAGE,
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            },
            contentType: 'application/json',
            cache: false,
            format: 'json',
            success: function (msg) {
                result = msg;
                localStorage.setItem ( "languages",  JSON.stringify(result.Languages) );
            },
            error: function(xhr, status, error){
                console.log("getting languages failed: " + error);
            }
        } );
        return result;
    }    

    getProducts ( queryData, callback = function(){} ) {
        var that = this;
        var result = null;
        $.ajax( {
            async: false,
            data: queryData,
            type: 'GET',
            url: that.prefix + Config.API_URL_PRODUCT,
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            },
            contentType: 'application/json',
            cache: false,
            format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("getting products failed: " + error);
            }
        } );
        return result;
    }

    filterProducts ( queryData, callback = function(){} ) {
        var that = this;
        var result = null;

        const _queryData = Object.assign ( {}, queryData );
        let qD = JSON.stringify ( _queryData );
        let sa = JSON.stringify ( _queryData.allergens );
        _queryData [ "allergens" ] = sa;
        $.ajax( {
            async: false,
            data: _queryData,
            type: 'POST',
            processData: true,
            url: that.prefix + Config.API_URL_PRODUCT_FILTER,
            /* headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            }, */
            //contentType: 'application/json',
            cache: false,
            //format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("getting products failed: " + error);
            }
        } );
        return result;
    }

    getCombos ( queryData, callback = function(){} ) {
        var that = this;
        var result = null;
        $.ajax( {
            async: false,
            data: queryData,
            type: 'GET',
            url: that.prefix + Config.API_URL_COMBO,
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            },
            contentType: 'application/json',
            cache: false,
            format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("getting combos failed: " + error);
            }
        } );
        return result;
    }

    filterCombos ( queryData, callback = function(){} ) {
        var that = this;
        var result = null;

        const _queryData = Object.assign ( {}, queryData );
        let qD = JSON.stringify ( _queryData );
        let sa = JSON.stringify ( _queryData.allergens );
        _queryData [ "allergens" ] = sa;
        $.ajax( {
            async: false,
            data: _queryData,
            type: 'POST',
            processData: true,
            url: that.prefix + Config.API_URL_COMBO_FILTER,
            /* headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            }, */
            //contentType: 'application/json',
            cache: false,
            //format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("getting products failed: " + error);
            }
        } );
        return result;
    }

    getProductsClasses ( callback = function(){} ) {
        var that = this;
        var result = null;
        $.ajax( {
            async: false,
            type: 'GET',
            url: that.prefix + Config.API_URL_PRODUCT_CLASS,
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            },
            contentType: 'application/json',
            cache: false,
            format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("getting products classes failed: " + error);
            }
        } );
        return result;
    }

    getProductsCategories ( callback = function(){} ) {
        var that = this;
        var result = null;
        $.ajax( {
            async: false,
            type: 'GET',
            url: that.prefix + Config.API_URL_PRODUCT_CATEGORY,
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            },
            contentType: 'application/json',
            cache: false,
            format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("getting products categories failed: " + error);
            }
        } );
        return result;
    }

    getProductsSubCategories ( queryData, callback = function(){} ) {
        var that = this;
        var result = null;
        $.ajax( {
            async: false,
            type: 'GET',
            data: queryData,
            url: that.prefix + Config.API_URL_PRODUCT_SUBCATEGORY,
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            },
            contentType: 'application/json',
            cache: false,
            format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("getting products sub-categories failed: " + error);
            }
        } );
        return result;
    }

    getAllergens ( callback = function(){} ) {
        var that = this;
        var result = null;
        $.ajax( {
            async: false,
            type: 'GET',
            url: that.prefix + Config.API_URL_ALLERGENS,
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            },
            contentType: 'application/json',
            cache: false,
            format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("getting products categories failed: " + error);
            }
        } );
        return result;
    }

    getLocations ( queryData, callback = function(){} ) {
        var that = this;
        var result = null;
        $.ajax( {
            async: false,
            data: queryData,
            type: 'GET',
            url: that.prefix + Config.API_URL_LOCATIONS,
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            },
            contentType: 'application/json',
            cache: false,
            format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("getting locations failed: " + error);
            }
        } );
        return result;
    }

    filter ( url, queryData, callback = function(){} ) {
        var that = this;
        var result = null;

        const _queryData = Object.assign ( {}, queryData );
        let qD = JSON.stringify ( _queryData );
        //let sa = JSON.stringify ( _queryData.allergens );
        //_queryData [ "allergens" ] = sa;
        $.ajax( {
            async: false,
            data: _queryData,
            type: 'GET',
            processData: true,
            url: that.prefix + url,
            /* headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            }, */
            //contentType: 'application/json',
            cache: false,
            //format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("filtering failed: " + error);
            }
        } );
        return result;
    }

    filterLocations ( queryData, callback = function(){} ) {
        var that = this;
        var result = null;

        const _queryData = Object.assign ( {}, queryData );
        let qD = JSON.stringify ( _queryData );
        //let sa = JSON.stringify ( _queryData.allergens );
        //_queryData [ "allergens" ] = sa;
        $.ajax( {
            async: false,
            data: _queryData,
            type: 'GET',
            processData: true,
            url: that.prefix + Config.API_URL_LOCATIONS,
            /* headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            }, */
            //contentType: 'application/json',
            cache: false,
            //format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("filtering locations failed: " + error);
            }
        } );
        return result;
    }

    filterUsers ( queryData, callback = function(){} ) {
        var that = this;
        var result = null;

        const _queryData = Object.assign ( {}, queryData );
        let qD = JSON.stringify ( _queryData );
        //let sa = JSON.stringify ( _queryData.allergens );
        //_queryData [ "allergens" ] = sa;
        $.ajax( {
            async: false,
            data: _queryData,
            type: 'GET',
            processData: true,
            url: that.prefix + Config.API_URL_USERS_PROFILES,
            /* headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            }, */
            //contentType: 'application/json',
            cache: false,
            //format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("filtering users failed: " + error);
            }
        } );
        return result;
    }

    filterClients ( queryData, callback = function(){} ) {
        var that = this;
        var result = null;

        const _queryData = Object.assign ( {}, queryData );
        let qD = JSON.stringify ( _queryData );
        //let sa = JSON.stringify ( _queryData.allergens );
        //_queryData [ "allergens" ] = sa;
        $.ajax( {
            async: false,
            data: _queryData,
            type: 'GET',
            processData: true,
            url: that.prefix + Config.API_URL_CLIENT,
            /* headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            }, */
            //contentType: 'application/json',
            cache: false,
            //format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("filtering clients failed: " + error);
            }
        } );
        return result;
    }

    filterPersons ( queryData, callback = function(){} ) {
        var that = this;
        var result = null;

        const _queryData = Object.assign ( {}, queryData );
        let qD = JSON.stringify ( _queryData );
        //let sa = JSON.stringify ( _queryData.allergens );
        //_queryData [ "allergens" ] = sa;
        $.ajax( {
            async: false,
            data: _queryData,
            type: 'GET',
            processData: true,
            url: that.prefix + Config.API_URL_PERSON,
            /* headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            }, */
            //contentType: 'application/json',
            cache: false,
            //format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("filtering persons failed: " + error);
            }
        } );
        return result;
    }

    filterCompanies ( queryData, callback = function(){} ) {
        var that = this;
        var result = null;

        const _queryData = Object.assign ( {}, queryData );
        let qD = JSON.stringify ( _queryData );
        //let sa = JSON.stringify ( _queryData.allergens );
        //_queryData [ "allergens" ] = sa;
        $.ajax( {
            async: false,
            data: _queryData,
            type: 'GET',
            processData: true,
            url: that.prefix + Config.API_URL_COMPANY,
            /* headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            }, */
            //contentType: 'application/json',
            cache: false,
            //format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("filtering companies failed: " + error);
            }
        } );
        return result;
    }

    filterLists ( url, queryData, callback = function(){} ) {
        var that = this;
        var result = null;

        const _queryData = Object.assign ( {}, queryData );
        let qD = JSON.stringify ( _queryData );
        //let sa = JSON.stringify ( _queryData.allergens );
        //_queryData [ "allergens" ] = sa;
        $.ajax( {
            async: false,
            data: _queryData,
            type: 'GET',
            processData: true,
            //url: that.prefix + Config.API_URL_LIST_PRICE,
            url: that.prefix + url,
            /* headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            }, */
            //contentType: 'application/json',
            cache: false,
            //format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("filtering list failed: " + error);
            }
        } );
        return result;
    }

    filterProductList ( url, queryData, callback = function(){} ) {
        var that = this;
        var result = null;

        const _queryData = Object.assign ( {}, queryData );
        let qD = JSON.stringify ( _queryData );
        let sa = JSON.stringify ( _queryData.allergens );
        _queryData [ "allergens" ] = sa;
        $.ajax( {
            async: false,
            data: _queryData,
            type: 'POST',
            processData: true,
            url: that.prefix + url,
            /* headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            }, */
            //contentType: 'application/json',
            cache: false,
            //format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("filtering products lists failed: " + error);
            }
        } );
        return result;
    }

    filterComboList ( url, queryData, callback = function(){} ) {
        var that = this;
        var result = null;

        const _queryData = Object.assign ( {}, queryData );
        let qD = JSON.stringify ( _queryData );
        let sa = JSON.stringify ( _queryData.allergens );
        _queryData [ "allergens" ] = sa;
        $.ajax( {
            async: false,
            data: _queryData,
            type: 'POST',
            processData: true,
            url: that.prefix + url,
            /* headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            }, */
            //contentType: 'application/json',
            cache: false,
            //format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("filtering products lists failed: " + error);
            }
        } );
        return result;
    }

    filterEmployees ( queryData, callback = function(){} ) {
        var that = this;
        var result = null;

        const _queryData = Object.assign ( {}, queryData );
        let qD = JSON.stringify ( _queryData );
        //let sa = JSON.stringify ( _queryData.allergens );
        //_queryData [ "allergens" ] = sa;
        $.ajax( {
            async: false,
            data: _queryData,
            type: 'GET',
            processData: true,
            url: that.prefix + Config.API_URL_EMPLOYEES,
            /* headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            }, */
            //contentType: 'application/json',
            cache: false,
            //format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("filtering employees failed: " + error);
            }
        } );
        return result;
    }

    getGeoData ( queryData, callback = function(){} ) {
        var that = this;
        var result = null;
        $.ajax( {
            async: false,
            data: queryData,
            type: 'GET',
            url: that.prefix + Config.API_URL_GEODATA,
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            },
            contentType: 'application/json',
            cache: false,
            format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("getting Geo Data failed: " + error);
            }
        } );
        return result;
    }

    getData ( url, queryData, callback = function(){} ) {
        var that = this;
        var result = null;
        $.ajax( {
            async: false,
            data: queryData,
            type: 'GET',
            url: that.prefix + url,
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            },
            contentType: 'application/json',
            cache: false,
            format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("getting Data failed: " + error);
            }
        } );
        return result;
    }

    pushData ( url, queryData, callback = function(){} ) {
        var that = this;
        var result = null;
        const _queryData = Object.assign ( {}, queryData );    
        $.ajax( {
            async: false,
            data: _queryData,
            type: 'POST',
            url: that.prefix + url,
            /* headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            },
            contentType: 'application/json', */
            cache: false,
            format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                //console.log("pushing Data failed: " + error + " -> " + status + " -> " + xhr );
                result = xhr.responseJSON;
            }
        } );
        return result;
    }

    getLocationsGroups ( queryData, callback = function(){} ) {
        var that = this;
        var result = null;
        $.ajax( {
            async: false,
            data: queryData,
            type: 'GET',
            url: that.prefix + Config.API_URL_LOCATIONSGROUPS,
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            },
            contentType: 'application/json',
            cache: false,
            format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("getting locations groups failed: " + error);
            }
        } );
        return result;
    }

    filterLocationsGroups ( queryData, callback = function(){} ) {
        var that = this;
        var result = null;

        const _queryData = Object.assign ( {}, queryData );
        let qD = JSON.stringify ( _queryData );
        let sa = JSON.stringify ( _queryData.allergens );
        _queryData [ "allergens" ] = sa;
        $.ajax( {
            async: false,
            data: _queryData,
            type: 'POST',
            processData: true,
            url: that.prefix + Config.API_URL_PRODUCT_FILTER,
            /* headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            }, */
            //contentType: 'application/json',
            cache: false,
            //format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("getting products failed: " + error);
            }
        } );
        return result;
    }

    getProducts2 ( data, callback = function(){}, async = false ) {
        var that = this;
        var result = null;
        $.ajax( {
            async: async,
            type: 'GET',
            //url: base_url + select,
            url: that.prefix + Config.API_URL_PRODUCT,
            contentType: 'application/json',
            cache: false,
            data: data,
            success: function(data) {			
                if (async) {
                    callback(data);
                } else {
                    result = data;
                }
            },
            error: function(xhr, status, error){
                if (xhr.status == 401){
                    localStorage.removeItem("apiUser");
                    that.reset()
                    if (async) {
                        callback(data);
                    }
                    else {
                        return that.getProducts(data, callback, async);;
                    }
                } else {
                    console.log("getting products failed.");
                    return {};
                }
            }
        } );
        if (!async){
            return result;
        }
    }

    callExternalAPI ( url, queryData, callback = function(){} ) {
        var that = this;
        var result = null;
        $.ajax( {
            async: false,
            data: queryData,
            type: 'GET',
            url: that.prefix + url,
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            },
            contentType: 'application/json',
            cache: false,
            format: 'json',
            success: function (msg) {
                    result = msg;
            },
            error: function(xhr, status, error){
                console.log("Calling external API failed: " + error);
            }
        } );
        return result;
    }

    removeAd(ad_ref) {
        var that = this;
        var result = false;
        $.ajax( {
            async: false,
            type: 'DELETE',
            url: that.prefix + Config.AD_API_URL + ad_ref + '/',
            contentType: 'application/json',
            success: function(data){			
                    result = true;
                    },
            error: function(xhr, status, error){
                if (xhr.status == 401){
                    console.log('Oops 401. couldn\'t remove ad '+ ad_ref);
                } else {
                    console.log("Error: couldn\'t remove ad.");
                    return true;
                }
            }
        } );
        return result;
    }

    getAdDetails(ad_ref) {
        // console.log(ad_ref)
        var that = this;
        var result = null;
        var data = 'ref=' + ad_ref;
        
        //console.log(data)
        $.ajax( {
            async: false,
            type: 'GET',
            //url: base_url + select,
            url: that.prefix + Config.AD_API_URL,
            contentType: 'application/json',
            cache: false,
            data: data,
            success: function(data){			
                    result = data;
                    },
            error: function(xhr, status, error){
                if (xhr.status == 401){
                    localStorage.removeItem("apiUser");
                    that.reset()
                    return that.getAds(data);
                } else {
                    console.log("getting ads failed.");
                    return {};
                }
            }
        } );
	//console.log ( result );
        return result;
    }

    updatePriceAnnonce(ad_ref, price) {
        var that = this;
        var result = false;
        var data = JSON.stringify({'price': price });
        $.ajax( {
            async: false,
            type: 'PATCH',
            url: that.prefix + Config.AD_API_URL + ad_ref + '/',
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            },
            contentType: 'application/json',
            cache: false,
            data: data,
            success: function (msg) {
                    result = true;
            },
            error: function(xhr, status, error){
                if (xhr.statusText == "Unauthorized"){
                    localStorage.removeItem("apiUser");
                    that.reset()
                    return that.updatePriceAnnonce(data);
                } else {
                    console.log("Updating price ad failed.");
                    return {};
                }
            }
        } );
        return result;
    }

    updateAnnounce(ad_ref, text_data, image_data, callback = function(){}) {
        var that = this;
        var result = null;
        // submit text data
        let data_json = JSON.stringify(text_data);
        //console.log(data_json);
        $.ajax( {
            async: true,
            type: 'PATCH',
            url: that.prefix + Config.AD_API_URL + ad_ref + '/',
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            },
            processData: false,
            contentType: false,
            cache: false,
            data: data_json,
            success: function (msg) {
                    result = msg;
                    ad_ref = result['ref'];
                    // console.log('Ad saved with ref : ', ad_ref);
                    // console.log('Uploading images to : ', that.prefix + Config.AD_API_URL + ad_ref + '/upload_image/')

                    $.ajax( {
                        async: true,
                        method: 'POST',
                        type: 'POST',
                        // enctype: 'multipart/form-data',
                        url: that.prefix + Config.AD_API_URL + ad_ref + '/upload_image/',
                        processData: false,
                        contentType: false,
                        cache: false,
                        data: image_data,
                        success: function (msg) {
                            result = msg;
                            callback();
                        },
                        error: function(xhr, status, error){
                            console.log('Image upload failed with status: ', xhr.status);
                        }
                    });
            },
            error: function(xhr, status, error){
            //    debugger;
                if (xhr.statusText == "Unauthorized"){
                    localStorage.removeItem("apiUser");
                    that.reset()
                    // that.auth('Traian', '1234');
                    return that.addAnnounce(data_json);
                } else {
                    console.log("Adding ad failed.");
                    return {};
                }
            }
        } );
    }

    addAnnounce(text_data, image_data, callback = function(){}) {
        var that = this;
        var result = null;
        // submit text data
        let data_json = JSON.stringify(text_data);
        let ad_ref = "";
        $.ajax( {
            async: true,
            method: 'POST',
            type: 'POST',
            url: that.prefix + Config.AD_API_URL,
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            },
            processData: false,
            contentType: false,
            cache: false,
            data: data_json,
            success: function (msg) {
                    result = msg;
                    ad_ref = result['ref'];
                    // console.log('Ad saved with ref : ', ad_ref);
                    // console.log('Uploading images to : ', that.prefix + Config.AD_API_URL + ad_ref + '/upload_image/')

                    $.ajax( {
                        async: true,
                        method: 'POST',
                        type: 'POST',
                        // enctype: 'multipart/form-data',
                        url: that.prefix + Config.AD_API_URL + ad_ref + '/upload_image/',
                        processData: false,
                        contentType: false,
                        cache: false,
                        data: image_data,
                        success: function (msg) {
                            result = msg;
                            callback();
                        },
                        error: function(xhr, status, error){
                            console.log('Image upload failed with status: ', xhr.status);
                        }
                    });
            },
            error: function(xhr, status, error){
            //    debugger;
                if (xhr.statusText == "Unauthorized"){
                    localStorage.removeItem("apiUser");
                    that.reset()
                    // that.auth('Traian', '1234');
                    return that.addAnnounce(data_json);
                } else {
                    console.log("Adding ad failed.");
                    return {};
                }
            }
        } );
    }

    register(data, callback = function(){}){
        var that = this;
        $.ajax( {
            async: true,
            type: 'POST',
            //url: base_url + select,
            url: that.prefix + Config.USER_API_URL,
            // contentType: 'application/json',
            cache: false,
            data: data,
            processData: true,
            success: function(data){
                        swal({
                            text: "Nous vous remercions. Vous allez recevoir un email contenant les indications pour l'activation de votre compte.",
                            icon: "success",
                        }).then((value)=>{
                            callback();
                        });
                        
                    },
            error: function(xhr, status, error){
                // debugger;
                // if ((JSON.parse(xhr.responseText).username == "A user with that username already exists.") || (JSON.parse(xhr.responseText).username == "Un utilisateur avec ce nom existe déjà."))
                if (xhr.status==400 && JSON.parse(xhr.responseText).username)
                {
                    swal({
                        text: "Adresse email déjà utilisée.",
                        icon: "error",
                    });
                } else {
                    swal({
                        text: "Il y a eu une erreur lors du traitement de votre demande. Veuillez réessayer ultérieurement et si le problème persiste, contactez-nous directement.",
                        icon: "error",
                    });
                }
            }
        } );
    }

    getPlaceDetails(search, callback = function(){}){
        var that = this;
        var query = "search="+search;
        $.ajax( {
            async: true,
            type: 'GET',
            //url: base_url + select,
            url: that.prefix + Config.PLACE_DETAILS_API_URL,
            // contentType: 'application/json',
            cache: false,
            data: query,
            processData: true,
            success: function(data){
                        callback(data);
                    },
            error: function(xhr, status, error){
               
            }
        } );
    }

    forgotPassword(username, callback=function(){}){
        var that = this;
        $.ajax( {
            async: true,
            type: 'GET',
            //url: base_url + select,
            url: that.prefix + Config.FORGOTPASS_API_URL,
            // contentType: 'application/json',
            cache: false,
            data: {username: username},
            processData: true,
            success: function(data){
                        swal({
                            text: "Vous allez recevoir un email contenant les details pour la reinitialisation de votre mot de passe.",
                            icon: "success",
                        }).then((value)=>{
                            callback();
                            window.location.href = "/";
                        });
                    },
            error: function(xhr, status, error){
               
            }
        } );
    }

    resetPass(data, callback = function(){}){
        var that = this;
        $.ajax( {
            async: true,
            type: 'POST',
            //url: base_url + select,
            url: that.prefix + Config.RESETPASS_API_URL,
            // contentType: 'application/json',
            cache: false,
            data: data,
            processData: true,
            success: function(data){
                        callback();
                    },
            error: function(xhr, status, error){
                swal({
                    text: "Il y a eu une erreur lors du traitement de votre demande. Veuillez réessayer ultérieurement et si le problème persiste, contactez-nous directement.",
                    icon: "error",
                  });
            }
        } );
    }

    sendMessageToAgent(data, callback = function(){}){
        var that = this;
        $.ajax( {
            async: true,
            type: 'POST',
            //url: base_url + select,
            url: sprintf(that.prefix + Config.CONTACT_SELLER_API_URL, data.ref),
            // contentType: 'application/json',
            cache: false,
            data: data,
            processData: true,
            success: function(data){
                        callback();
                    },
            error: function(xhr, status, error){
                swal({
                    text: "Il y a eu une erreur lors du traitement de votre demande. Veuillez réessayer ultérieurement et si le problème persiste, contactez-nous directement.",
                    icon: "error",
                  });
            }
        } );
    }

    activateAccount(data, callback = function() {}){
        var that = this;
        $.ajax( {
            async: true,
            type: 'POST',
            //url: base_url + select,
            url: that.prefix + Config.VALIDATE_ACCOUNT_API_URL,
            // contentType: 'application/json',
            cache: false,
            data: data,
            processData: true,
            success: function(data){
                    swal({
                        text: "Nous vous remercions. Vous pouvez maintenant vous connecter à votre compte!",
                        icon: "success",
                    }).then((value)=>{
                        window.location.href = "/";
                    });
                    },
            error: function(xhr, status, error){
                swal({
                    text: "Il y a eu une erreur lors du traitement de votre demande. Veuillez réessayer ultérieurement et si le problème persiste, contactez-nous directement.",
                    icon: "error",
                  });
            }
        } );
    }

    deleteAccount(callback = function() {}){
        var that = this;
        $.ajax( {
            async: true,
            type: 'DELETE',
            //url: base_url + select,
            url: that.prefix + Config.USER_UPDATE_API_URL,
            // contentType: 'application/json',
            cache: false,
            processData: true,
            success: function(data){
                        callback();
                    },
            error: function(xhr, status, error){
                swal({
                    text: "Il y a eu une erreur lors du traitement de votre demande. Veuillez réessayer ultérieurement et si le problème persiste, contactez-nous directement.",
                    icon: "error",
                  });
            }
        } );
    }

    updateAccount(data, callback = function(){}){
        var that = this;
        $.ajax( {
            async: true,
            type: 'PATCH',
            //url: base_url + select,
            url: that.prefix + Config.USER_UPDATE_API_URL,
            // contentType: 'application/json',
            cache: false,
            data: data,
            processData: true,
            success: function(data){
                        swal({
                            text: "Nous avons mis à jour vos coordonnées.",
                            icon: "success",
                        }).then((value)=>{
                            callback();
                        });
                        
                    },
            error: function(xhr, status, error){
                swal({
                    text: "Il y a eu une erreur lors du traitement de votre demande. Veuillez réessayer ultérieurement et si le problème persiste, contactez-nous directement.",
                    icon: "error",
                  });
            }
        } );
    }

    sendMandatRequest(data, callback = function(){}){
        function csrfSafeMethod(method) {
            // these HTTP methods do not require CSRF protection
            return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
        }

        function getCookie(name) {
            var cookieValue = null;
            if (document.cookie && document.cookie !== '') {
                var cookies = document.cookie.split(';');
                for (var i = 0; i < cookies.length; i++) {
                    var cookie = cookies[i].trim();
                    // Does this cookie string begin with the name we want?
                    if (cookie.substring(0, name.length + 1) === (name + '=')) {
                        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                        break;
                    }
                }
            }
            return cookieValue;
        }

        var that = this;
      
        $.ajax( {
            async: true,
            type: 'POST',
            //url: base_url + select,
            url: that.prefix + Config.CONTACT_MANDAT,
            // contentType: 'application/json',
            cache: false,
            data: data,
            processData: true,
            crossDomain: false,
            success: function(data){
                        callback();
                    },
            error: function(xhr, status, error){
                swal({
                    text: "Il y a eu une erreur lors du traitement de votre demande. Veuillez réessayer ultérieurement et si le problème persiste, contactez-nous directement.",
                    icon: "error",
                  });
            },
            beforeSend: function(xhr, settings) {
                let csrf = getCookie('csrftoken');
                if (csrf){
                    if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
                        xhr.setRequestHeader("X-CSRFToken", csrf);
                    }
                }
            }
        } );
    }

    sendContactTeam(data, callback = function(){}){        
        function csrfSafeMethod(method) {            
            // these HTTP methods do not require CSRF protection
            return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
        }

        function getCookie(name) {
            var cookieValue = null;
            if (document.cookie && document.cookie !== '') {
                var cookies = document.cookie.split(';');
                for (var i = 0; i < cookies.length; i++) {
                    var cookie = cookies[i].trim();
                    // Does this cookie string begin with the name we want?
                    if (cookie.substring(0, name.length + 1) === (name + '=')) {
                        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                        break;
                    }
                }
            }
            return cookieValue;
        }

        var that = this;
      
        $.ajax( {
            async: true,
            type: 'POST',
            //url: base_url + select,
            url: that.prefix + Config.CONTACT_TEAM,
            // contentType: 'application/json',
            cache: false,
            data: data,
            processData: true,
            crossDomain: false,
            success: function(data){                        
                        callback();
                    },
            error: function(xhr, status, error){
                swal({
                    text: "Il y a eu une erreur lors du traitement de votre demande. Veuillez réessayer ultérieurement et si le problème persiste, contactez-nous directement.",
                    icon: "error",
                  });
            },
            beforeSend: function(xhr, settings) {
                let csrf = getCookie('csrftoken');
                if (csrf){
                    if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
                        xhr.setRequestHeader("X-CSRFToken", csrf);
                    }
                }
            }
        } );
    }


};
